import { useAppContext } from "../../context/AppContext";

import { logOut } from "../../services/services";

import "./ItemInfoUserHeader.css";

import useTranslate from "../../utils/translate";

const ItemInfoUserHeader = () => {

    const t = useTranslate();

    const [context, setContext] = useAppContext();

    //cuando cerremos sesión vamos a borrar todas las cookies donde están los datos basicos del usuario y la token
    function cerrarSesion(){
        logOut().then((res) => {
        console.log(res.message);
        });
    } 

    const getStringUserByStringEmail = (email) => {
        if(!email) return ("");
        let string = "";
        for (let i = 0; i < email.length; i++) {
            if (email[i] !== "@") {
                string += email[i];
            } else {
                break;
            }
        }
        return string;
    }

    const getFirstName = (name) => {
        if(!name) return ("");
        let string = "";
        for (let i = 0; i < name.length; i++) {
            if (name[i] !== " ") {
                string += name[i];
            } else {
                break;
            }
        }
        return string;
    }

    
    return (
        <div className="contInfoUser">
            <div className="contTitleUser">

                {
                    context.user.displayName ?
                        <span className="titleText">{getFirstName(context.user.displayName)}</span>
                        :
                        <span className="titleText">{getStringUserByStringEmail(context.user.email)}</span>
                }

                <img src={
                    !context.user.photoURL ? 
                    require("../../images/noImgProfile.jpg"):
                    context.user.photoURL 
                } 
                className="imgUser" alt="user" />
               

            </div>


            <div className="contContOption">
                <div className={"contOptions show"}>
                   
                    <div className="option" onClick={cerrarSesion}>
                        <div className="contImgIcon">
                            <img height={20} width={20} src="https://img.icons8.com/ios/50/000000/logout-rounded.png" className="imgOption" alt="Option" />
                        </div>
                        <span className="textOption">
                            {t({
                                es: "Cerrar sesión",
                                en: "Log out"
                            })}
                        </span>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ItemInfoUserHeader;