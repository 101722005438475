import { createContext, useContext } from "react";

export const AppContext = createContext();

//esta función es para llamar directamente al contexto sin tener que importar el useContext y el AppContext en cada componente
export const useAppContext = () => {
    return useContext(AppContext);
}; 


