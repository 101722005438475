// Description: Función que recibe la marca de agua y devuelve un objeto con todos los parametros necesarios para utilizarla
export default async function getObjMarcaAgua(marcaAgua) {

    let blob = dataURLtoBlob(marcaAgua);
    let imageBitmap = await createImageBitmap(blob);

    let width = imageBitmap.width;
    let height = imageBitmap.height;
    let urlPreview = URL.createObjectURL(blob);

    return({
        // marcaAguaBlob: blob,
        imageBitmap,
        url: urlPreview,
        width: width,
        height: height,
    });

}

//por ahora usaremos esta funcion para convertir el base64 a Blob, pero en un futuro almacenaremos el archivo blobl en la DB, solo que por ahora me da pereza hacerlo
function dataURLtoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }