export default function DeleteDataInfo(){
    return <div className="container pt-3">
        <h1>¿Cómo eliminar todos mis datos almacenados en PickAlbum.com? </h1>
        <br></br>
        <p><strong>Para eliminar todos sus datos por favor siga los siguientes pasos:</strong></p>
        <p>
            <strong>1. </strong>Ingrese a su cuenta de PickAlbum.com
        </p>
        <p>
            <strong>2. </strong>En la parte superior derecha de la pantalla haga click en la opcion "Mi info"
        </p>
        <p>
            <strong>3. </strong>En la parte inferior de la pantalla haga click en el boton "Eliminar cuenta"
        </p>
        <p>
            <strong>4. </strong>Seleccione la opción de confirmar o "Ok", en el panel emergente
        </p>
        
    </div>
}