import React, {useState, useEffect, useRef} from 'react';

import { useHistory } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faFolderOpen, faTrash, faEdit, faCog } from '@fortawesome/free-solid-svg-icons'

import getObjMarcaAgua from '../functions/getObjMarcaAgua';

import ParamsMarcaAgua from './ParamsMarcaAgua/ParamsMarcaAgua';
import ponerMarcaAgua from './funcPonerMarca'

import ModalChangeMarcaAgua from '../ModalChangeMarcaAgua/ModalChangeMarcaAgua';

import ModalViewImgMarca from './ModalViewImgMarca/ModalViewImgMarca';

import Compressor from 'compressorjs';

import { getMarcaAgua } from '../../services/services';

import "./inputMarcaAgua.css";
import Loading from '../Loading/Loading';

import { Modal } from 'reactstrap';

import useTranslate from '../../utils/translate';


export default function InputMarcaAgua({images, setimages, modoPrueba = false}){

    const [loadingCompressImgs, setloadingCompressImgs] = useState(false);

    const [sizeAllImages, setsizeAllImages] = useState(0);

    // const idFotografo = window.localStorage.getItem("ID_USER");

    const [showModalSetMarca, setshowModalSetMarca] = useState(false)
    const toggleModalSetMarca = () => {
        setshowModalSetMarca(!showModalSetMarca);
    }

    const t = useTranslate();

    const history = useHistory();

    const [showModalAdvertMarca, setshowModalAdvertMarca] = useState(false);
    const [objMarcaAgua, setobjMarcaAgua] = useState({});

    useEffect(() => {

        setHayMarcaAgua();
    }, [])

    /* useEffect(() => {
        console.log('images state changed');
        console.log(images);
    }, [images]); */
    


//para veriricar si existe marca de agua
const setHayMarcaAgua = async ()=>{

    //aquí evaluamos si estamos en modo prueba, donde el usuario no tiene marca de agua, así que cargamos y establecemos una imagen predefinida de manera local.
    if(modoPrueba){
        let img = new Image();
        img.src = require('./../../images/marcaEjemploPng.png');
        img.onload = async () => {
            let imageBitmap = await createImageBitmap(img);
            // console.log(imageBitmap);
            setobjMarcaAgua({
                url: require('./../../images/marcaEjemploPng.png'),
                imageBitmap: imageBitmap,
                width: imageBitmap.width,
                height: imageBitmap.height,
            });
        };
        return;
    }

    getMarcaAgua().then(res => {
        // console.log(res.data);
        if(res.data.marcaAgua){

            getObjMarcaAgua(res.data.urlMarcaAgua).then(objMarca => {
                // console.log(objMarca);
                setobjMarcaAgua(objMarca);
            });

        }else{
            setshowModalAdvertMarca(true);
           /*  alert("No hay marca de agua, por favor suba una");
            history.push("/panel"); */
        }

    }).catch(err => {
        console.log(err); 
    });

    
}





const changeInput = (e) => {

    //esto es el indice que se le dará a cada imagen, a partir del indice de la ultima foto
    let indexImg = 0;

    //aquí evaluamos si ya hay imágenes antes de este input, para saber en dónde debe empezar el index del proximo array
    if (images.length > 0) {
        indexImg = images[images.length - 1].index + 1;
    }


    const loadFiles = async (e) => {
        
        const files = e.currentTarget.files; 
        
        //primero haremos un preview de las imágenes, mientras se comprimen
        let imgs = [];
        for (let i = 0; i < files.length; i++) {
            let img = {
                index: indexImg + i,
                url: URL.createObjectURL(files[i]),
                name: files[i].name,
                preview: true,
            }
            imgs.push(img);
        }
        console.log('preview files:');
        console.log(imgs);
        setloadingCompressImgs(true);
        setimages([...images, ...imgs]);


        function convertObjtToArray(objt) {
            return Object.keys(objt).map(function (key) { return objt[key]; });
        }

        const filesArray = convertObjtToArray(files);

        const myPromise = (file) => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {

                    maxWidth: 1920,
                    maxHeight: 1080,
                    quality: 0.6,

                    async success(resultBlob) {

                        //obtenemos la url de preview de la imagen
                        let urlView = URL.createObjectURL(resultBlob);

                        /* // si la imagen pesa mas de 100kb, re reducimos las dimensiones a 2/3 de su tamaño
                        if(resultBlob.size > 100000){
                            console.log('compress with canvas');
                            const imageBitmap = await createImageBitmap(resultBlob);
                            const canvas = document.createElement('canvas');
                            canvas.width = Math.round(imageBitmap.width * 0.6666666666666666);
                            canvas.height = Math.round(imageBitmap.height * 0.6666666666666666);
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(imageBitmap, 0, 0);
                            ctx.canvas.toBlob((blob) => {
                                resolve({ 
                                    index: file.index, 
                                    url: URL.createObjectURL(blob), 
                                    name: resultBlob.name, 
                                    imgBlob: blob,
                                });
                            }, 'image/jpeg', 0.6);

                        }else{
                            resolve({ 
                                index: file.index, 
                                url: urlView, 
                                name: resultBlob.name, 
                                imgBlob: resultBlob,
                            });
                        } */

                        resolve({ 
                            index: file.index, 
                            url: urlView, 
                            name: resultBlob.name, 
                            marcaPuesta: false,
                            size: resultBlob.size,
                            imgBlob: resultBlob,
                        });

                        

                        /* //**blob to dataURL**
                        function blobToDataUrl(blob) {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = reject;
                                reader.readAsDataURL(blob);
                            });
                        }
 */
        
                    },
                    error(err) {

                      console.log(err.message);

                    },
    
                });
              });
        }

        const objFiles = await Promise.all(

            filesArray.map(async (file, index) => {

                file.index = indexImg + index;
                return await myPromise(file);

            })

        )
        
        //console.log(arrayNegocios);
        return objFiles;
        
      };

    function readmultifiles(e) {

        loadFiles(e).then((objFiles) => {
            console.log('new files:');
            console.log(objFiles);

            let size = 0;
            objFiles.forEach(img => {
                size += img.size;
            })
            setsizeAllImages(sizeAllImages + size);

            //ahora revocamos las urls de las imágenes que se usaron para previsualizar, esto es para ahorrar memoria
            images.forEach(img => {
                if(img.preview){
                    URL.revokeObjectURL(img.url);
                }
            })

            setimages([...images, ...objFiles]);
            setloadingCompressImgs(false);
            setshowModalPreview(false);
        })

    }

    readmultifiles(e);
};


/* ESTO ES... REALMENTE... ES... PANA EN SERIO ¿POR QUÉ EL ESTADO NO ACTUALIZA, PORQUE ESTO ES TAN EXTRAÑO, AHORA NI SUQUIERA PUEDO CONTROLAR ESTO CON EL useEffect DE AQUÍ ABAJO, ¿ENTONCES QUÉ PUEDO HACER?.. AHH ._., ESTO ES... ES... UNA... COMPLETISIMAAAAAAAAAAAAAAAAAAAAAAA MIEEEEEEEEEEEEERRRDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA, LA REEEEEPUUUUUUUUUUUUTAAAAAAAAAAAAAAAAAA MIEEEEEEEEEEEEEEEEEERDAAAAAAAAAAAAAAAAAAAAAAAA EEEEEEEEES ESSSSSSSSTOOOOOOOO.

Ni hablar, voy a seguir con otra parte del proyecto arreglaré esto después, aunque también me limita porque quiero hacer los tutoriales sobre esto :'C */

//parece que react tiene bastantes fallos, como por ejemplo que el estado no actualiza instantaneamente por lo que me toca hacer un useEffect para verificar que se haya actualizado el estado correctamente


//jajajja volví un año después y esto sigue siendo igual de jodido
//jajaja volví 2 añs después, el año pasado lo arreglé, aún así ni saqué el proyecto, esperemos que este año sí, ya vamos pa 3 años xd
 
const [settingMarcas, setsettingMarcas] = useState(false)
// const [progressSettingMarcasAgua, setprogressSettingMarcasAgua] = useState(0);

const [paramsMarcaAgua, setparamsMarcaAgua] = useState({});


const refProgressMarcas = useRef()
const refNameImgProgress = useRef()





const ponerMarcasAgua = async (params) => {


    console.log("array images: ");
    console.log(images);

    //console.log(urlMarcaAgua);
    let newArray = [];
    let cont = 0;

    const onCompleted = (image) => {
        //console.log(image);
        cont++;
        newArray.push(image);
        let carga = parseInt((cont / images.length) * 100, 10);
        // console.log(carga);
        refProgressMarcas.current.value = carga;
        refNameImgProgress.current.innerHTML = image.name;
        //setprogressSettingMarcasAgua((v) => carga);
        if (cont === images.length) {
            //primero ordenamos cada imagen según su index
            newArray.sort((a, b) => a.index - b.index);
            setimages(newArray);

            //estableceremos el nuevo peso de las imagenes
            let newSize = 0;
            newArray.forEach(img => {
                newSize += img.size;
            })
            setsizeAllImages(newSize);
            
            console.log("terminado");
            console.log(newArray);
            setsettingMarcas(false);
        }
    };

    //aqui debería hacer un filtro para solo procesar las nuevas imágenes que no tengan marca de agua puesta (image.marcaPuesta)
    setsettingMarcas(true);
    for (let index = 0; index < images.length; index++) {
      let image = images[index];
        //console.log(image);

        let omitir = false;
        //aquí omitimos las imágenes que ya tienen marca de agua puesta, siempre y cuando la funcion tenga el parametro newsOnly en true
        if(params.newsOnly && image.marcaPuesta) {
            omitir = true;
        };

        if (!omitir) {
            //si le quitamos el seTimeout, no funciona, no se por qué, parace que el navegador no tiene tiempo de actualizar el estado
            setTimeout(function () {
                ponerMarcaAgua({
                    objImagen: image, 
                    objMarcaAgua,
                    ...paramsMarcaAgua
                })
                .then((objImagen) => {
                    //console.log(image.name);
                    onCompleted(objImagen);
                });
            }, 100 * index);
        }else{
            setTimeout(function () {
                onCompleted({...image});
            }, 100 * index);
        }
        
    }
  };



  



/*
const ponerMarcasAgua = async ()=>{
    //console.log(urlMarca)
    
    alert('Por favor espera mientras se aplican las marcas de agua');
    var cont = 0;
    let newArray = [];
    let totalImgs = images.length;



    //images.forEach(element => {
        

    for (let index = 0; index < images.length; index++) {let element = images[index];
        
        
        //await setTimeout(() => {
          
        
            
            //images.forEach(element => {
        //aquí evaluamos que dicha imagen no tenga marca de agua antes
        if(!element.marcaPuesta){
            let urlWithMarca = ponerMarcaAgua(element.url, urlMarca);
            newArray.push({index: element.index, url: urlWithMarca, marcaPuesta: true});
        }else{
            newArray.push(element);
        }

        //más metodos rudimentarios :c
        cont++;
        //console.log(cont);
        //refProgressMarcas.current.value += 10;
       // console.log(progressSettingMarcasAgua);
        console.log(cont);
        //alert(cont);
        //document.getElementById('texto_carga_marcas').innerHTML = 'poniendo marcas en la imagen ' + cont + ' de ' + totalImgs;
        //setconstApliMarcas(cont);
        //setprogressSettingMarcasAgua(parseInt(((cont / totalImgs) * 100), 10));


        //aquí evaluamos si ya acabamos con todas la imágenes
        if(totalImgs === cont){
            setsettingMarcas(false);
            setprogressSettingMarcasAgua(0);
        }
        


    //}, (1000));
    //});

    };

    //console.log(progressSettingMarcasAgua)

    //document.getElementById('texto_carga').innerHTML = '';
    setimages(newArray);
    console.log(newArray);
    alert('proceso terminado');
}
*/

//+-+-+-+-+-+



function deleteImg(index){
   
    //primero obtenemos el indice de la imagen que vamos a borrar
    let indice = images.findIndex((img) => {
        return img.index === index;
    })

    //ahora revocamos la url de la imagen
    URL.revokeObjectURL(images[indice].url);

    //ahora eliminamos la imagen del array
    let newArray = images.filter((img) => {
        return img.index !== index;
    })

    //ahora restamos el peso de la imagen borrada al peso total
    setsizeAllImages(sizeAllImages - images[indice].size);

    setimages(newArray);

}



//imagen que vamos a previsualizar
const [imgToPreView, setimgToPreView] = useState({});
const [showModalPreview, setshowModalPreview] = useState(false)
function toggleModal(){
    setshowModalPreview(!showModalPreview);
}
function previewImg(i){
    console.log('preview img ' + i)
    //aqui filtramos la imagen y la establecemos
    let img = images.filter((img) => {
        return img.index === i;
    })
    setimgToPreView(img[0]);
    toggleModal();
}


    return <div >

        {/* 
        <hr></hr>
        <label>
            <span>marca agua</span>
            <input hidden type="file" onChange={setMarca}></input>
        </label>
        <br></br>
        <img hidden src={urlMarca}></img>
        <hr></hr>
        <br></br>
        */}
        {/*
            <img hidden alt='marca_temporal' src={require('./../../images/marcaEjemploNegra.jpg')} id="img"></img>
         */}


         <Modal centered isOpen={showModalAdvertMarca}  >
            <div style={{padding: '2rem'}}>
                <h3>
                    {t({
                        es: "No hay marca de agua, por favor establece alguna.",
                        en: "There is no watermark, please set one."
                    })}
                </h3>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-secondary' onClick={()=>{
                        //lo enviamos al panel
                        history.push('/panel');
                    }}>
                        {t({
                            es: "Aceptar",
                            en: "Accept"
                        })}
                    </button>
                </div>
            </div>
         </Modal>
        
        
        <div className='container-fluid contPadre'> 


        {/* row de dos columnas */}
        <div className='contSettings row pb-3'>
            <div className='col-12 col-lg-6 '>
                <div>
                    <label className="btnInput">
                        <span>
                            {t({es: "Seleccionar imágenes", en: "Select images"})}
                        </span>
                        <FontAwesomeIcon icon={faFolderOpen} />
                        <input hidden type="file" multiple onChange={changeInput}></input>
                    </label>   

                    <div className='contSettingsMarks'>

                        <ParamsMarcaAgua paramsMarcaAgua={paramsMarcaAgua} setparamsMarcaAgua={setparamsMarcaAgua} />

                        {
                            loadingCompressImgs ?
                            <Loading/>
                            :
                            <>
                                <button onClick={ponerMarcasAgua} disabled={images.length < 1} className="btnAllImgs">
                                    <span>
                                        {t({es: "Aplicar a todas las imágenes", en: "Apply to all images"})}
                                    </span>
                                    <FontAwesomeIcon className="text-light" icon={faClone} />
                                </button>

                                <button onClick={()=>{ponerMarcasAgua({newsOnly: true})}} disabled={images.length < 1} className="btnSomeImgs">
                                    <span>
                                        {t({es: "Aplicar a imágenes sin marca", en: "Apply to images without watermark"})}
                                    </span>
                                    <FontAwesomeIcon className="text-light" icon={faClone} />
                                </button>
                            </>
                        }

                    </div>
                </div>
            </div>
            <div className='col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end'>
                {
                    objMarcaAgua.url ? 
                        <div className='contimgViewMarcaAgua'>
                            {/* visualización de la marca de agua */}
                            <img className='imgViewMarcaAgua' src={objMarcaAgua.url} />
                            <button 
                                className='buttonConfingMarcaAgua' 
                                onClick={()=>{
                                    if(modoPrueba){
                                        history.push('/panel');
                                    }else{
                                        toggleModalSetMarca();
                                    }
                                }}
                            >
                                <span>
                                    {t({es: "Marca de agua ", en: "Change watermark "})}
                                </span>
                                <FontAwesomeIcon icon={faCog} />
                            </button>
                        </div>
                    : 
                        <div>
                            {/* si aún no ha cargado, ponemos un spiner loading */}
                            <Loading/>

                        </div>

                }
            </div>

        </div>

        <ModalChangeMarcaAgua isOpen={showModalSetMarca} toggle={toggleModalSetMarca} />

                
            
        {
            /*
            <LoadingBarSettingMarcas isLoading={settingMarcas} reff={refProgressMarcas} ></LoadingBarSettingMarcas>
            */
            settingMarcas && <div>
            {/* <h6>Aplicando marcas de agua</h6> */}
            {/*
                <progress max="100"  value={progressSettingMarcasAgua}></progress>
            */}
            <Modal centered isOpen={true}  >
                <div style={{padding: '15px', display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{t({es: 'Procesando', en: 'Processing'})}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-1rem', paddingBottom: '2rem'}}>
                    <span style={{color: 'green'}} ref={refNameImgProgress}>name_image.jpg</span>
                    <progress max="100"  ref={refProgressMarcas}></progress>
                </div>
            </Modal>
            </div>

        }

        <div className="contImgs row w-100">
            <h3 className='textSizeImgs'>
                {
                    images.length > 0 &&
                    t({es: "Peso imagenes: ", en: "Images Size: "}) 
                }
                {
                    images.length > 0 &&
                    <span className='nowrap'>
                        {(sizeAllImages / 1000000).toFixed(2) + ' MB  / 1000MB (1GB)'}
                    </span>
                }
                
            </h3>
            {/*
            <VistaCargandoImgs loading={loadingImages} />
            */}
            {/*
            <div className="col-6 col-sm-4 col-lg-3 square">
                <div className="content_img">
                    <button className="position-absolute btn btn-danger" onClick={()=>{deleteImg('indice')}}>x</button>
                    <img src={require('./images/camera.png')} className="img-responsive"></img>
                </div>
            </div>
            */}
            {images.map((imagen, index) => 
                <div className="col-6 col-sm-4 col-lg-3 square" key={index}>
                    <div className="content_imgs">
                        <div className='position-absolute' >
                            <button className="btn btnDeleteImg" onClick={deleteImg.bind(this, imagen.index)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button className="btn btnShowImg" onClick={previewImg.bind(this, imagen.index)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </div>
                        <div className='div_image' style={{backgroundImage: `url("${imagen.url}")`}} onClick={()=>{previewImg(imagen.index)}}>
                        </div>
                       {/*  <img loading="lazy" alt='imageSesion' src={imagen.url} className="img-responsive imgItem" onClick={()=>{previewImg(imagen.index)}}></img> */}
                    </div>
                </div>
            )}
            {/* 
            <VistaCargandoMarcas loading={loadingMarcas} contLoadMarc={contLoadMarc} />
            */}
        </div>
        {/* <p id="texto_carga"></p>

        <p id="texto_carga_marcas"></p> */}
        {/*alert que dice que esperes mientras se aplican las marcas de agua */}
        {/* <div className="alert alert-warning alert-dismissible fade" role="alert" id="alertWaitMarcas">
            Por favor espera mientras se aplican las marcas de agua
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> */}

        
        
        <ModalViewImgMarca 
            isOpen={showModalPreview} 
            toggleModal={toggleModal}
            image={imgToPreView}
            setimage={setimgToPreView}
            setimgToPreView={setimgToPreView}
            marcaAgua={objMarcaAgua}
            images={images}
            setimages={setimages}
            sizeAllImages={sizeAllImages}
            setsizeAllImages={setsizeAllImages}
        />
        </div>
        

    </div>
}
 







