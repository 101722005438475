import { useState, useEffect } from 'react';
import { 
    Button, 
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEraser, faSlidersH, faChevronLeft, faChevronRight, faEye} from '@fortawesome/free-solid-svg-icons'

import ParamsMarcaAgua from '../ParamsMarcaAgua/ParamsMarcaAgua';
import ponerMarcaAgua from '../funcPonerMarca';

import './ModalViewImgMarca.css';
import { set } from 'date-fns';

import useTranslate from '../../../utils/translate';

export default function ModalViewImgMarca({isOpen, toggleModal, image, setimage, setimgToPreView, marcaAgua, images, setimages, sizeAllImages, setsizeAllImages}){

    const [zoomActived, setzoomActived] = useState(false);

    const [paramsMarcaAgua, setparamsMarcaAgua] = useState({});

    useEffect(() => {
        setparamsMarcaAgua(image.paramsMarcaAgua ? image.paramsMarcaAgua : {});
    }, [image]);

    const t = useTranslate();

    const [open, setOpen] = useState("");
    const toggle = (id) => {
        if (open === id) {
        setOpen("");
        } else {
        setOpen(id);
        }
    }; 

    
    useEffect(() => {
        //desactivamos el scroll del body
        if(isOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);


    const goRight = () => {
        if (image.index === images.length - 1) {} else {
            setimage(images[image.index + 1]);
        }
    }

    const goLeft = () => {
        if (image.index === 0) {} else {
            setimage(images[image.index - 1]);
        }
    }

    //aquí detectaremos la presion de las teclas izquierda y derecha y ejecutaremos las funciones goRight y goLeft
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 39) {
                goRight();
            }
            if (e.keyCode === 37) {
                goLeft();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [image]);


    if(!isOpen){
        return null;
    }

    return <>
        <div className='backgroundModalView' >
            <div className='contModalViewMarca' >


                <div className='divBackground' onClick={toggleModal}>

                </div>




                    {
                        image.index !== 0 &&
                        <div className='contBtnArrow contBtnLeft'>
                            <button onClick={goLeft} disabled={image.index === 0}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                        </div>

                    }
                    {
                        image.index !== images.length - 1 &&
                        <div className='contBtnArrow contBtnRight'>
                            <button onClick={goRight} disabled={image.index === images.length - 1}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    }
                    <div className='contImg'>
                        {/* Panel desplegable opciones imagen */}
                        <Accordion open={open} toggle={toggle} style={{position: 'absolute', zIndex: 30000, alignSelf: 'flex-start'}} >
                            <AccordionItem>
                                <AccordionHeader targetId="1">
                                    <span style={{fontWeight: 'bold', color: '#595959', marginRight: 8}}>
                                        <FontAwesomeIcon icon={faSlidersH} style={{marginRight: 7}} />
                                        {" "} Modificar marca de Agua {" "} 
                                    </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    {/* inputs params marca agua */}
                                    <ParamsMarcaAgua
                                        paramsMarcaAgua={paramsMarcaAgua}
                                        setparamsMarcaAgua={setparamsMarcaAgua}
                                    />
                                    <div style={{
                                        marginTop: 10,
                                    }}>
                                        {/* reaplicar marca agua */}
                                        <Button
                                        style={{
                                            marginRight: 10,
                                        }}
                                            className='btn btn-primary mb-2 mb-lg-0'
                                            onClick={()=>{
                                                ponerMarcaAgua({
                                                    objImagen: image, 
                                                    objMarcaAgua: marcaAgua, 
                                                    ...paramsMarcaAgua
                                                }).then((objImagen)=>{
                                                    // actualizar imagen en el array de imágenes
                                                    let newImages = images.map((img)=>{
                                                        if(img.index === objImagen.index){
                                                            return objImagen;
                                                        }else{
                                                            return img;
                                                        }
                                                    });
                                                    console.log(newImages);
                                                    setimages(newImages);

                                                    //ahora actualizamos el peso de las imágenes
                                                    let oldImgSize = image.size;
                                                    let newImgSize = objImagen.size;
                                                    let newsizeAllImages = sizeAllImages - oldImgSize + newImgSize;
                                                    setsizeAllImages(newsizeAllImages);

                                                    setimgToPreView(objImagen);
                                                    setOpen("");
                                                }).catch((err)=>{console.log(err)});
                                            }}
                                        >
                                            {t({
                                                es: 'Aplicar Marca de Agua ',
                                                en: 'Apply Watermark '
                                            })}
                                            <FontAwesomeIcon icon={faClone} />
                                        </Button>

                                        {/* eliminar marca de agua */}
                                        <Button
                                            className='btn'
                                            style={{
                                                backgroundColor: "#ff0000",
                                                color: "#fff",
                                                border: "1px solid #ff0000",
                                            }}
                                            onClick={()=>{
                                                //primero obtenemos la url de la imagen sin marca de agua
                                                let url = URL.createObjectURL(image.imgBlob);

                                                let newImg = {
                                                    ...image,
                                                    url,
                                                    marcaPuesta: false,
                                                    paramsMarcaAgua: {},
                                                    size: image.imgBlob.size,
                                                }

                                                //ahora vamos a remplazar la imagen en el array de imágenes
                                                let newImages = images.map((img)=>{
                                                    if(img.index === image.index){
                                                        return newImg;
                                                    }else{
                                                        return img;
                                                    }
                                                });
                                                console.log(newImages);
                                                setimages(newImages);

                                                //ahora actualizamos el peso de las imágenes
                                                let oldImgSize = image.size;
                                                let newImgSize = newImg.size;
                                                let newsizeAllImages = sizeAllImages - oldImgSize + newImgSize;
                                                setsizeAllImages(newsizeAllImages);

                                                setimgToPreView(newImg);
                                                setOpen("");
                                                // toggleModal();
                                            }}
                                        >
                                            {t({
                                                es: 'Eliminar Marca de Agua ',
                                                en: 'Delete Watermark '
                                            })}
                                            <FontAwesomeIcon icon={faEraser} />
                                        </Button>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        <img 
                            className={zoomActived ? 'zoomImg' : ''} 
                            alt="Esta es la imagen que estamos visualizando" 
                            src={image.url}
                            onClick={()=>{{
                                setzoomActived(!zoomActived);
                            }}}
                        />
                        <a className='btnOpenImg' href={image.url} target="_blank">
                            <span style={{fontSize: ".7rem"}}>
                                {image.name}
                            </span>
                            { ' | ' }
                            <span style={{whiteSpace: "nowrap"}}>
                                {t({
                                    es: 'Abrir imagen ',
                                    en: 'Open img '
                                })}
                                <FontAwesomeIcon icon={faEye} />
                            </span>
                        </a>
                        <button className='btnCloseModalView' onClick={toggleModal}>
                            X
                        </button>
                    </div>

            </div>
        </div>
            
    </>

}


