import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import './AdminSesiones.css'

   
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'

import MarcaAguaConfig from '../configMarcaAgua/configMarcaAgua';
import Loading from '../Loading/Loading';

import { getSesionesFotografo } from '../../services/services';

import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

import useTranslate from '../../utils/translate';

function ShowSeleccionado(props){
    if(props.seleccionado){
        return <span className="position-absolute seleccionado">
            Selected{" "}
            <FontAwesomeIcon className="text-light" icon={faCheck} />
            </span>

    }else{
        return <div>

        </div>
    }
}

export default function AdminSesiones(){

    //lo que hago aquí con el contexto es almacenar la lista de sesiones en él, para que cada vez que naveguemos aquí no se vea el slider de carga, aunque siempre que navegamos aquí lo pedimos al servidor
    const [context, setcontext] = useContext(AppContext);

    const t = useTranslate();

    const getSesiones = async ()=> {

        getSesionesFotografo().then(res => {
            console.log('sesiones getted');
            if(res.data.auth){
                console.log(res.data.sesiones);
                setcontext({
                    ...context,
                    sesiones: res.data.sesiones
                });
            }else{
                console.log(t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}));
            }
        })

    }


useEffect(()=>{
    getSesiones();
}, []);



//esta es la url de la marca de agua, puede estar en base64 o en la url normal del archivo, se establece cuando recargamos la pagina, solo que en modo desarrollo no funcionaba, por politicas de CORS, así que la establecimos manualmente subiendola con un input, en el servidor será más sencillo
    const [urlMarcaDev, seturlMarcaDev] = useState();


    return <div className="bodyConentFullHeight">
        <br/>
        <MarcaAguaConfig
            urlMarcaDev={urlMarcaDev}
            seturlMarcaDev={seturlMarcaDev}
        ></MarcaAguaConfig>
        <div className="container contAlbumes">
            <hr></hr>
            <h3 className='titleAdmSesiones'>
                {t({
                    es: "Mis Álbumes",
                    en: "My Albums"
                })}
            </h3>
            <br></br>
            <div className="row">
                {
                    context.sesiones === null ? 
                        <Loading/>
                    :
                    <ListViewAlbumes sesiones={context.sesiones}/>
                }
                
            </div>
            <br></br>
            <div className='d-flex'>
                <Link
                    className="btn btn-success"
                    to="/saveSesion"
                >
                    <span>{t({es: 'Nuevo álbum', en: 'New album'})}</span>
                    <FontAwesomeIcon className="text-light" icon={faPlus} />
                </Link>

            </div>

            <br/><br/>
        </div>
    </div>
}


const ListViewAlbumes = ({sesiones})=> {

    const t = useTranslate();
    
    if(sesiones.length < 1){
        return <div>
            <p className='text-center'>
                {t({
                    es: "Aún no tienes álbumes, crea uno nuevo.",
                    en: "You don't have albums yet, create a new one."
                })}
            </p>
        </div>
    }

    return sesiones.map((sesion) => 
        <div className="col-6 col-sm-4 col-lg-3 square" key={sesion._id}>
            <div className="content_img">
                <ShowSeleccionado seleccionado={sesion.seleccionado}></ShowSeleccionado>
                <span className="position-absolute nombreAlbum" style={{bottom: 0}}>{sesion.categoria}</span>
                <Link to={"/editSesion/" + sesion._id} className="position-absolute w-100 h-100 z_index_2"></Link>
                <img src={sesion.archivos[0].url} className="img-responsive cursor-pointer"></img>
            </div>
        </div>
)
}
