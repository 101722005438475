import React, {useState, useEffect} from 'react';
import {useParams  } from 'react-router-dom';

import SelectMyFotos from '../Components/SelectMyFotos/selectMyFotos';
import Footer from '../Components/Footer/Footer';

export default function WebSelectMyFotos(){
    const { idFotografo } = useParams();

    return <div className='bodyContainer' >
        
        <div className="d-flex align-items-center bodyConentFullHeight" >
            <SelectMyFotos idFotografo={idFotografo}></SelectMyFotos>

        </div>
        
    </div>
}
