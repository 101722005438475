// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEdO_jdoB5RBEyXPimzEQ3zNFHemxlzp4",
  authDomain: "react-fotografos.firebaseapp.com",
  projectId: "react-fotografos",
  storageBucket: "react-fotografos.appspot.com",
  messagingSenderId: "439597124860",
  appId: "1:439597124860:web:babff29b013acd23be5b79"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
