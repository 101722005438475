import { useAppContext } from "../context/AppContext";

//esta función es para traducir textos, se usa así: t({es: 'texto en español', en: 'text in english'}). 
const useTranslate = (texts) => {
    const [context, setcontext] = useAppContext();
    return (texts) => {
        return context.language === "es" ? texts.es : texts.en;
    }
};

export default useTranslate;