const ponerMarcaAgua = ({objImagen, objMarcaAgua, opacidadMarca, porcentajeMarcaAgua, posicionMarcaAgua}) => { 
    return new Promise(async (resolve, reject) => {

      if(!opacidadMarca) opacidadMarca = 15;
      if(!porcentajeMarcaAgua) porcentajeMarcaAgua = 100;
      if(!posicionMarcaAgua) posicionMarcaAgua = "scattered";

      opacidadMarca = opacidadMarca / 100;
      porcentajeMarcaAgua = porcentajeMarcaAgua / 100;
    
      
// -----------------------------------------------------------------------------------------------
    // Proceso canva logo:


      // Creamos un nuevo canvas
      var canvas2 = document.createElement("canvas");
      //var canvas2 = document.getElementById("canvas2");
      var ctx2 = canvas2.getContext("2d");   
      
      // console.log(objMarcaAgua);
      const logoBitMap = objMarcaAgua.imageBitmap;

      // Obtenemos nuevo tamaño de logo redondeado a entero, ya que el tamaño de la imagen no puede ser decimal
      const widthLogo = Math.round(objMarcaAgua.width * porcentajeMarcaAgua);
      const heightLogo = Math.round(objMarcaAgua.height * porcentajeMarcaAgua);

      

      // Asignamos la misma altura y ancho de la imagen al canvas
      ctx2.canvas.width = widthLogo;
      ctx2.canvas.height = heightLogo; 

      // Dibujamos la imagen en el canvas
      ctx2.drawImage(logoBitMap, 0, 0, widthLogo, heightLogo);
  
      // Obtenemos la matriz de información de la imagen logo
      var imgData2 = ctx2.getImageData(0, 0, canvas2.width, canvas2.height);
      var data2 = imgData2.data;
  
     
  
// -----------------------------------------------------------------------------------------------

      // Process canvas original image:



      // Obtenemos el canvas
      var canvas1 = document.createElement("canvas");
      //var canvas1 = document.getElementById("canvas1");
      var ctx1 = canvas1.getContext("2d");

      //como el proceso con new Image parecía dar errores porque la imagen virtual no alcanzaba a cargar a veces entonces lo hice mejor con un objeto imageBitmap así no necesitaré cargar imágenes de manera grafica  alsdjflaskdyflask dfjlasiydflkasjdflj siempre estoy nervioso, es dificil socialiar, dios mio ayudame. 

      // console.log("objImagen:", objImagen);

      //dios mio funcionó, después de meses, soy un puto crack, al fin creo que podré sacar este proyecto de 3 años. espero todo empiece a ir mejor.
      const imageBitmap = await createImageBitmap(objImagen.imgBlob);
      // console.log("imageBitmap:", imageBitmap);

      let widthOriginalImg = imageBitmap.width;
      let heightOriginalImg = imageBitmap.height;

      //el compressorjs está haciendo esto por mí a la hora de cargar la imagen, pero por si acaso lo dejo aquí
      //si la imagen es más grande que 1920x1080 entonces la reducimos a ese tamaño pero sin perder la relación de aspecto
      /* if(imageBitmap.width > 1920 || imageBitmap.height > 1080){
        console.log("imagen muy grande");

        let heightTemp = imageBitmap.height;
        let porcentaje = 1920 / imageBitmap.width;
        //ni el ancho ni el alto pueden ser mayores a 1920x1080 respectivamente
        if(Math.round(heightTemp * porcentaje > 1080)){
          porcentaje = 1080 / imageBitmap.height;
        }
        
        widthOriginalImg = Math.round(imageBitmap.width * porcentaje);
        heightOriginalImg = Math.round(imageBitmap.height * porcentaje);
        console.log("reducida a: ", widthOriginalImg, heightOriginalImg, "px");

      }*/
      
      // Asignamos la misma altura y ancho de la imagen al canvas
      ctx1.canvas.width = widthOriginalImg;
      ctx1.canvas.height = heightOriginalImg;

      // Dibujamos la imagen en el canvas
      ctx1.drawImage(imageBitmap, 0, 0, widthOriginalImg, heightOriginalImg);
      
      // Obtenemos la matriz de información de la imagen original
      var imgData1 = ctx1.getImageData(0, 0, canvas1.width, canvas1.height);
      var data1 = imgData1.data;


// -----------------------------------------------------------------------------------------------
        
      



      //estas variables regulan la opacidad de la marca de agua
      let porcentajeImgOriginal = 1 - opacidadMarca;
  
      var posicion = 0;
      var posicionLogo = 0;
      var contHLogo = 0;
      var contWLogo = 0;


      //estás variables son para prevenir que el logo se salga de la imagen original, se modifican en caso de que el logo sea más grande que la imagen original
      var maxWLogo = widthLogo;
      var maxHLogo = heightLogo;

      var inicialHlogo = 0;
      var inicialWlogo = 0;

      //variables de iteradores, por defecto son la altura y widthLogoancho de la imagen original
      var Hinicial = 0;
      var Winicial = 0;

      var Hfinal = heightOriginalImg;
      var Wfinal = widthOriginalImg;
      
      //aquí evaluamos si la marca de agua será única o dispersa
      if(posicionMarcaAgua !== "scattered"){

        //aquí definimos la posición de la marca de agua
        switch(posicionMarcaAgua){
          case 'center':
            Hinicial = Math.floor((heightOriginalImg - heightLogo) / 2);
            Winicial = Math.floor((widthOriginalImg - widthLogo) / 2);
            break;
          case 'top-left':
            Hinicial = 0;
            Winicial = 0;
            break;
          case 'top-right':
            Hinicial = 0;
            Winicial = widthOriginalImg - widthLogo;
            break;
          case 'bottom-left':
            Hinicial = heightOriginalImg - heightLogo;
            Winicial = 0;
            break;
          case 'bottom-right':
            Hinicial = heightOriginalImg - heightLogo;
            Winicial = widthOriginalImg - widthLogo;
            break;
          default:
            //en caso de que no se especifique una posición válida, se pondrá en el centro
            Hinicial = Math.floor((heightOriginalImg - heightLogo) / 2);
            Winicial = Math.floor((widthOriginalImg - widthLogo) / 2);
            break;
        }


        //todo este tipo de funcionalidades de tamaño maximo de logo las dejaré para después para no perder más tiempo, avanzaré con el proyecto

        /* //aquí prevenimos que el logo no vaya a ser más grande que la imagen original y se sobreescriba
        if(heightLogo > heightOriginalImg){
          Hinicial = 0;
          Winicial = 0;
          contWLogo = (widthLogo - widthOriginalImg) / 2;
          contHLogo = (heightLogo - widthOriginalImg) / 2;
          maxWLogo = widthOriginalImg;
          maxHLogo = heightOriginalImg;
        } */
        
        Hfinal = Hinicial + heightLogo;
        Wfinal = Winicial + widthLogo;

        //aquí prevenimos que el logo no vaya a ser más grande que la imagen original y se sobreescriba
        if(widthLogo > widthOriginalImg){

          Winicial = 0;

          inicialWlogo = (widthLogo - widthOriginalImg) / 2;
          maxWLogo = widthOriginalImg;

          Wfinal = widthOriginalImg;

        }

      }

      contWLogo = inicialWlogo;

      //iterador vertical
      for (var i = Hinicial; i < Hfinal; i++) {

        if (contHLogo === maxHLogo) {
          contHLogo = 0;
        }

        //iterador horizontal
        for (var d = Winicial; d < Wfinal; d++) {

          if (contWLogo === maxWLogo) {
            contWLogo = inicialWlogo;
          }

          /* es importante entender la asignación de posición de la matriz de datos de las imágenes para poder entender este algoritmo. 
          
          toma en cuenta que es una matriz de 1 dimensión, por lo que se debe calcular la posición de cada pixel de la imagen en base a su posición en la matriz de datos. 
          Esto se hace multiplicando el contador altura por el ancho de la imagen, y luego sumando el contador ancho. esto da como resultado la posición del pixel en la matriz de datos.
          
          Se multiplica por 4 porque cada pixel tiene 4 valores: rojo, verde, azul y opacidad. 

          */
          posicion = (i * widthOriginalImg + d) * 4;
          posicionLogo = (contHLogo * widthLogo + contWLogo) * 4;


          if (

            //skip transparent pixels
            data2[posicionLogo + 3] > 0 

          ) {
            data1[posicion] = data2[posicionLogo] * opacidadMarca + data1[posicion] * porcentajeImgOriginal;
            data1[posicion + 1] =
              data2[posicionLogo + 1] * opacidadMarca + data1[posicion + 1] * porcentajeImgOriginal;
            data1[posicion + 2] =
              data2[posicionLogo + 2] * opacidadMarca + data1[posicion + 2] * porcentajeImgOriginal;
          }
          contWLogo++;
        }
        contWLogo = 0;
        contHLogo++;
      }
  
      // Asignamos la imagen al Canvas
      ctx1.putImageData(imgData1, 0, 0);
      ctx2.putImageData(imgData2, 0, 0);
      //}
  
      // Convertimos el Canvas a una imagen Blob
      canvas1.toBlob((newImgBlob) => {

          

          let newUrl = URL.createObjectURL(newImgBlob);
          // console.log('terminado poner marca a: '+ objImagen.name);
          resolve({
            ...objImagen,
            url: newUrl,
            imgBlobWithMarca: newImgBlob,
            paramsMarcaAgua: {
              opacidadMarca: opacidadMarca*100,
              porcentajeMarcaAgua: porcentajeMarcaAgua*100,
              posicionMarcaAgua: posicionMarcaAgua,
            },
            size: newImgBlob.size,
            marcaPuesta: true,
          });
        }, 
        // se debe especificar el tipo de imagen, en este caso es jpeg, los formatos de tipo png no tienen tanta compressión y por lo tanto son más pesados. aunque tienen mayor calidad, en nuestro proyecto debemos priorizar la velocidad de carga de las imágenes.
        "image/jpeg", 
        //este es el porcentaje de compresión de la imagen, se recomienda que sea entre 0.5 y 0.9
        .6
      );
      
    });
  };
  
  export default ponerMarcaAgua;