import React, {useState} from 'react';
import { useHistory  } from 'react-router-dom';
import ModalLoading from '../Components/ModalLoading/ModalLoading';

import '../css/AdminSesiones.css'
import '../css/saveAlbum.css'

import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import InputMarcaAgua from '../Components/inputMarcasAgua/inputMarcaAgua';

import { saveSesion } from '../services/services';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTranslate from '../utils/translate';


/* function VistaCargandoImgs(props) {
    const loading = props.loading;
    if (loading) {
        return <p >cargando imágenes</p>;
    }else{
        return <p >Nada pendiente</p>;
    }
} */

/* function VistaCargandoMarcas(props) {
    const loading = props.loading;
    if (loading) {
        return <p >cargando imagene {props.contLoadMarc}</p>;
    }else{
        return <p >Nadaa pendiente</p>;
    }
} */










export default function SaveSesion({loading, logged}){

// const idFotografo = window.localStorage.getItem("ID_USER");

const [nameSesion, setnameSesion] = useState('');

const [images, setimages] = useState([]);

const [isOpenModalLoading, setisOpenModalLoading] = useState(false)

const [progressUpdateImgs, setprogressUpdateImgs] = useState(0)


const t = useTranslate();

/*
const [contImage, setcontImage]  = useState(0);
const [totalImages, settotalImages]  = useState(0);
*/


/*
const [loadingMarcas, setloadingMarcas] = useState(false);
const [contLoadMarc, setcontLoadMarc] = useState(0);
*/



const history = useHistory();

function allImgsHaveMarca(){
    let allHaveMarca = true;
    images.forEach(img => {
        if(!img.marcaPuesta){
            allHaveMarca = false;
        }
    });
    return allHaveMarca;
}

const guardar = () => {

    //esto es para advertir que no se han puesto marcas de agua a todas las imágenes
    if(!allImgsHaveMarca()){
        const sinMarcas = window.confirm("Algunas imágenes no tienen marca de agua, ¿Deseas continuar?");
        if(!sinMarcas){
            return;
        }
    }

    setisOpenModalLoading(true);

    const data = {
        nameSesion,
        images
    }

    console.log(data);
    
    saveSesion(data).then(res => {
        console.log(res.data);
        setisOpenModalLoading(false);
        if(res.data.auth){
            history.push('/panel');
        }else{
            toast('⚠️ ' + t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}), {
                theme: "dark",
                autoClose: 1500,
            });
        }
    });

}









    return <div className='bodyContainer' >
        
        <Header loading={loading} logged={logged}/>
        <div className="bodyConentFullHeight container">
            <br/>
            <h2 className='titleCreaSesion'>Crea un nuevo álbum</h2>
            <div className="row d-none">
                <canvas id="canvas1" hidden></canvas>
                <canvas id="canvas2" hidden></canvas>
            </div>
            <br/>
            <div className="input-group contInputName">
                <div className='d-flex' style={{flex: '1 1'}}>
                    <div className="input-group-prepend ">
                        <span className="input-group-text">Nombre del álbum:</span>
                    </div>
                    <input type="text" className='form-control inputName' onChange={(e)=>{setnameSesion(e.target.value)}} value={nameSesion}></input>
                </div>
                <div className='contBtnSave'>
                    <button className="btnSave" onClick={guardar}>
                        <span>{t({es: 'Guardar álbum ', en: 'Save album '})}</span>
                        <FontAwesomeIcon className="text-light" icon={faSave} />
                    </button>
                </div>
            </div>
            {/* <div className='d-flex'>
                <span className='nameSesionText'>Nombre de la sesión:</span> 
                <input type="text" className='form-control' placeholder="nombre sesión" onChange={(e)=>{setnameSesion(e.target.value)}} value={nameSesion}></input>
            </div> */}
            { isOpenModalLoading &&
		        <h6>Subiendo imgs {progressUpdateImgs}</h6>
            }
            <div style={{margin: "1rem 0"}}>
                <InputMarcaAgua images={images} setimages={setimages}></InputMarcaAgua>
            </div>

           
			<ModalLoading isOpen={isOpenModalLoading}/>
        </div>

        <ToastContainer/>

        <Footer/>

    </div>
}
