import { useState } from "react";

import useTranslate from "../utils/translate";

import Header from "../Components/Header/Header";

import AdminSesiones from "../Components/AdminSesiones/AdminSesiones";

import Footer from "../Components/Footer/Footer";


export default function Panel({loading, logged}){
  
    const t = useTranslate();
  
    if(!loading){
  
      return <div className="bodyContainer" >
        <Header loading={loading} logged={logged}/> 
        <AdminSesiones />
        <Footer/>
      </div>
    }else{
      return <div>
        <p>{t({es: 'Cargando...', en: 'Loading...'})}</p>
      </div>
    } 
  }
  