import { Link } from 'react-router-dom'
import './Footer.css'
import useTranslate from "../../utils/translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faDollarSign, faEnvelope, faFileSignature, faInfoCircle, faMugHot } from '@fortawesome/free-solid-svg-icons';

export default function Footer(){

  const t = useTranslate();

  return <div className='footer'>
    <div className='container'>
      <div className='row'> 
        <div className='colFooter col-12 col-md-4'>
          <h5 className='titleFooter'>
            {t({
              es: 'Información',
              en: 'Information'
            })}
          </h5>
          <ul>
            <li>
              <Link to='/'>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
                <span>{t({es: 'Nuestra Herramienta', en: 'Our Tool'})}</span>
              </Link>
            </li>
            <li>
              <Link to='/'>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faDollarSign}/>
                </div>
                <span>{t({es: 'Precios', en: 'Prices'})}</span>
              </Link>
            </li>
            <li>
              <Link to='/privacyPolicy'>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faFileSignature}/>
                </div>
                <span>
                  {t({
                    es: 'Política de privacidad',
                    en: 'Privacy policy'
                  })}
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className='colFooter col-12 col-md-4'>
          <h5 className='titleFooter'>
            {t({
              es: 'Contáctanos',
              en: 'Contact us'
            })}
          </h5>
          <ul>
            <li>
              <a href='https://wa.me/573135903889' target={'_blank'}>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faWhatsapp}/>
                </div>
                <span>WhatsApp: +573135903889</span>
              </a>
            </li>
            <li>
              <a href='https://t.me/programeitor'target={'_blank'}>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faTelegram}/>
                </div>
                <span>Telegram: @programeitor</span>
              </a>
            </li>
            <li>
              <a href='infopickalbum@gmail.com'target={'_blank'}>
                <div className='contIcon'>
                  <FontAwesomeIcon icon={faEnvelope}/>
                </div>
                <span>{t({es: 'Correo: ', en: 'Email: '})}</span>
                <span>infopickalbum@gmail.com</span>
              </a>
            </li>
          </ul>
        </div>
        <div className='colFooter col-12 col-md-4'>
          <Link to='/' className='contLogo'>
            <img
              src={require('./../../images/selectIcon.png')}
            />
            <h1 className='text'>PickAlbum.com</h1>
          </Link>

          <h5 className=''>
            <span>☕ </span>
            {t({
              es: 'Invitame a un café: ',
              en: 'Buy me a coffee: '
            })}
          </h5>
          <ul>
            <li>
            <a href='https://www.paypal.com/donate/?hosted_button_id=SWJH2X4AATKQA' className='btn btnDonacion d-flex' style={{marginRight: 10, color: 'white'}}>
                <img height="25" width="25" src={require('./../../images/paypal.png')}></img>
                <span style={{marginLeft: 4, marginRight: 6}}>
                  PayPal
                </span>
            </a>
            </li>
          </ul>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <span>
          copyrigth © 2020 - {new Date().getFullYear()} PickAlbum.com
        </span>
      </div>
    </div>
  </div>
}