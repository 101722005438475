import React, {useState, useEffect} from 'react';
import {Link, useHistory, useParams  } from 'react-router-dom';
import axios from 'axios'


export default function IngresarCodigo(){
    const [codigo, setcodigo] = useState('');

    return <div className="h-100 w-100">
        <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <h4>Ingresar código de tu sesión</h4>
            <input className="height_input" value={codigo} onChange={(e)=>{setcodigo(e.target.value)}} type="text" placeholder="Codigo"></input>
            <Link to={"/select/"+codigo} className="btn btn-success">Ingresar</Link>
        </div>
    </div>
}