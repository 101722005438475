import React, {useState, useEffect} from 'react';
import { 
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

import getObjMarcaAgua from '../functions/getObjMarcaAgua';

import ParamsMarcaAgua from '../inputMarcasAgua/ParamsMarcaAgua/ParamsMarcaAgua';
import ponerMarcaAgua from '../inputMarcasAgua/funcPonerMarca';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faFolderOpen } from '@fortawesome/free-solid-svg-icons'

import Compressor from 'compressorjs';

import { faEye } from '@fortawesome/free-solid-svg-icons'

import ModalChangeMarcaAgua from '../ModalChangeMarcaAgua/ModalChangeMarcaAgua';

import { getMarcaAgua } from '../../services/services';

import './configMarcaAgua.css';
import ModalShowSingleImg from './ModalShowSingleImg/ModalShowSingleImg';

import useTranslate from '../../utils/translate';

export default function MarcaAguaConfig() {


    const [objectImgPrueba, setobjectImgPrueba] = useState({});
    const [hayMarcaAgua, sethayMarcaAgua] = useState(false);
    const [urlMarcaAgua, seturlMarcaAgua] = useState('');
    const [objMarcaAgua, setobjMarcaAgua] = useState(null);

    const t = useTranslate();
    
    const [showMarcaConfig, setshowMarcaConfig] = useState("");
    const toggleshowMarcaConfig = (id) => {
        if (showMarcaConfig === id) {
            setshowMarcaConfig("");
        } else {
            setshowMarcaConfig(id);
        }
    };


    useEffect(()=>{
        funGetMarcaAgua();
    }, []);


    const funGetMarcaAgua = async ()=>{

        getMarcaAgua().then(res => {
            // console.log(res.data);
            sethayMarcaAgua(res.data.marcaAgua);
            if(res.data.marcaAgua){

                getObjMarcaAgua(res.data.urlMarcaAgua).then(objMarca => {
                    // console.log(objMarca);
                    setobjMarcaAgua(objMarca);
                    seturlMarcaAgua(objMarca.url);

                });

            }else{
                console.log('no hay marca');
                setshowMarcaConfig("1");
            }

        }).catch(err => {
            console.log(err);
        });
    
    }

    
    return <div className="container pb-2 pt-2" >
            {/* Panel desplegable opciones imagen */}
        <Accordion 
            open={showMarcaConfig} 
            toggle={toggleshowMarcaConfig}
        >
            <AccordionItem>
                <AccordionHeader targetId="1">
                    <div>
                        <h3 className='titleConfigMarca'>
                            <FontAwesomeIcon icon={faClone} style={{marginRight: 7}} />
                            <span>
                                {t({
                                    es: "Configurar Marca de Agua",
                                    en: "Configure Watermark"
                                })}
                            </span>
                        </h3>
                        <span className='descripConfigMarca'>
                            {t({
                                es: "Agrega una marca de agua a tus imágenes para que no sean robadas.",
                                en: "Add a watermark to your images so they are not stolen."
                            })}
                        </span>

                    </div>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <div className=" mt-3">
                        {/* <img hidden src={require('./images/marcaEjemploNegra.jpg')} id="img"></img> */}
                        {/* <img src={urlMarcaAgua} id="img"></img> */}

                        <div className="container-fluid">
                            <hr/>
                            <div className="row">
                                <div className="columnConfigMarca col-12 col-sm-4 " style={{maxHeight: '100%'}}>
                                    <MarcaAgua urlMarcaAgua={urlMarcaAgua} hayMarca={hayMarcaAgua}></MarcaAgua>
                                </div>
                                <div className="columnConfigMarca col-12 col-sm-4">
                                    <FotoPrueba setobjectImgPrueba={setobjectImgPrueba} urlImgPrueba={objectImgPrueba.url}></FotoPrueba>
                                    
                                </div>
                                <div className="columnConfigMarca col-12 col-sm-4">
                                    <ResultadoMarcaAgua marcaAgua={objMarcaAgua} urlImgPrueba={objectImgPrueba.url} objectImg={objectImgPrueba}></ResultadoMarcaAgua>
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
        

    </div>   
}
    



    //esto y sus siguientes son de nuestra marca de agua y donde podemos establecer una nueva
    function MarcaAgua(props){

        
        const [showModalSetMarca, setshowModalSetMarca] = useState(false)
        const toggleModalSetMarca = () => {
            setshowModalSetMarca(!showModalSetMarca);
        }

        const t = useTranslate();


        if(props.hayMarca){
            return <div >
                {/*<!-- Button trigger modal -->*/}
                <div >
                <button
                    type="button"
                    className="btn btn-primary mb-2"
                    onClick={toggleModalSetMarca}
                >
                    <span>
                        {t({
                            es: "Cambiar mi marca de agua ",
                            en: "Change my watermark "
                        })}
                    </span>
                    <FontAwesomeIcon className="text-light" icon={faFolderOpen} />
                </button>
                </div>


                <ModalChangeMarcaAgua isOpen={showModalSetMarca} toggle={toggleModalSetMarca} />
                <div style={{backgroundColor: "#d1d1d1", padding: 10, display: 'inline-block'}}>
                    <img src={props.urlMarcaAgua} alt="imagen marca de agua actual del usuario" className="imgRespondive"></img>
                </div>


            </div>
        }else{ 
            return <div>
                <p>
                    {t({
                        es: "No tienes una marca de agua, sube una para que se aplique a tus imágenes.",
                        en: "You don't have a watermark, upload one to apply to your images."
                    })}
                </p>
                {/*<!-- Button trigger modal -->*/}
                <button type="button" className="btn btn-primary mb-2" onClick={toggleModalSetMarca}>
                    <span>
                        {t({
                            es: "Subir mi marca de agua ",
                            en: "Upload my watermark "
                        })}
                    </span>
                </button>

                <ModalChangeMarcaAgua isOpen={showModalSetMarca} toggle={toggleModalSetMarca} />

            </div>
        }
    }










    //esto y sus siguientes es la parte donde subimos una foto para probar la marca de agua
    function FotoPrueba(props){
        const [fotoSeleccionada, setfotoSeleccionada ] = useState(false);
        
        const t = useTranslate();


        function selectFoto(e){
            var uploadFile = e.target.files[0];

            if (!window.FileReader) {
                alert(t({
                    es: "El navegador no soporta la lectura de archivos",
                    en: "The browser does not support file reading"
                }));
                return;
            }

            if (!(/\.(jpg|png|gif)$/i).test(uploadFile.name)) {
                alert(t({
                    es: "El archivo a adjuntar no es una imagen",
                    en: "The file to attach is not an image"
                }));
            }
            else{

                new Compressor(uploadFile, {
                    maxWidth: 1920,
                    maxHeight: 1080,
                    quality: 0.6,
                    success(resultBlob) {

                        let urlPreview = URL.createObjectURL(resultBlob);

                        props.setobjectImgPrueba({
                            index: 0, 
                            url: urlPreview, 
                            name: resultBlob.name, 
                            marcaPuesta: false,
                            imgBlob: resultBlob,
                        });
                        
                        setfotoSeleccionada(true);
                    } 
                })


                /* // Creamos el objeto de la clase FileReader
                let reader = new FileReader();
                

                // Leemos el archivo subido y se lo pasamos a nuestro fileReader
                reader.readAsDataURL(e.target.files[0]);

                // Le decimos que cuando este listo ejecute el código interno
                reader.onload = (e)=>{
                    var img = new Image();
                    img.src = e.target.result;
                    img.onload = function () {
                        if (uploadFile.size > 40000000)
                        {
                            alert(t({
                                es: "El archivo es demasiado grande",
                                en: "The file is too large"
                            }))
                        }else{
                            props.seturlImgPrueba(e.target.result)
                            setfotoSeleccionada(true);
                        }
                    };
                };  */


            }
        }


        return <div>
            <label className="btn btn-warning mb-2">
                <span>
                    {t({
                        es: "Probar mi marca de agua ",
                        en: "Test my watermark "
                    })}
                </span>
                <FontAwesomeIcon icon={faFolderOpen} />
                <input type="file" hidden onChange={selectFoto} />
            </label>
            <FotoPruSeleccionada isSelected={fotoSeleccionada} url={props.urlImgPrueba}></FotoPruSeleccionada>
        </div>
    }

        function FotoPruSeleccionada(props){
            const t = useTranslate();
            if(props.isSelected){
                return <div className="d-flex flex-column align-items-center">
                    <img src={props.url} className="imgRespondive"></img>
                </div>
            }else{
                return <div>
                    <p>
                        {t({
                            es: "Sube alguna foto y aplicale una marca de agua de prueba",
                            en: "Upload a photo and apply a test watermark"
                        })}
                    </p>
                </div>
            }
        }


    //esto para la parte del restultado de la marca de agua
    function ResultadoMarcaAgua(props){
        const [marcaAplicada, setmarcaAplicada] = useState(false);
        const [urlMarcaAplicada, seturlMarcaAplicada] = useState();

        const [paramsMarcaAgua, setparamsMarcaAgua] = useState({});

        const t = useTranslate();
        async function aplicarMarca(){
            // console.log(paramsMarcaAgua);
            ponerMarcaAgua({
                objImagen: props.objectImg, 
                objMarcaAgua: props.marcaAgua, 
                ...paramsMarcaAgua
            }).then((objImagen)=>{
                seturlMarcaAplicada(objImagen.url);
                setmarcaAplicada(true);
            })
        }
        return <div>
            <button className="btn btn-success" style={{marginBottom: '.5rem'}} onClick={aplicarMarca}>
                <span>
                    {t({
                        es: "Aplicar marca de agua ",
                        en: "Apply watermark "
                    })}
                </span>
                <FontAwesomeIcon className="text-light" icon={faClone} />
            </button>
            <ResultadoFinalMarca aplicada={marcaAplicada} url={urlMarcaAplicada}></ResultadoFinalMarca>

            <ParamsMarcaAgua
                paramsMarcaAgua={paramsMarcaAgua}
                setparamsMarcaAgua={setparamsMarcaAgua}
            />

        </div>
    }




        function ResultadoFinalMarca({aplicada, url}){
            
            const t = useTranslate();
            
            const [showModalPreview, setshowModalPreview] = useState(false)
            function toggleModal(){
                setshowModalPreview(!showModalPreview);
            }

            if(aplicada){
                return <div>
                    <div className='contImgPruebaMarca'>
                        <button onClick={toggleModal} className='btn btn-primary btnViewPruebaMarca'>
                            {t({
                                es: "Vista previa ",
                                en: "Preview "
                            })}
                            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                        </button>
                        <img className="imgRespondive" src={url} onClick={toggleModal}></img>
                    </div>
                    
                    {/* 
                    <div>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#prevImgPrueva">
                        cambiar mi marca de agua
                        </button>
                    </div> */}
                    
                    
                    <ModalShowSingleImg
                        urlImg={url} isOpen={showModalPreview} toggleModal={toggleModal}
                    />
                </div>
            }else{
                return <div>
                    <p>
                        {t({
                            es: "Aplica la marca de agua a tu imagen de prueba",
                            en: "Apply the watermark to your test image"
                        })}
                    </p>
                </div>
            }
        }

 
