import { Modal } from 'reactstrap';
import "./ModalLoading.css";

export default function ModalLoading({isOpen}){
    return <Modal
        centered
        isOpen={isOpen}
        className='modal-sm '
    >
        <div className='d-flex justify-content-center pt-5 pb-5'>
            <div className="lds-dual-ring"></div>
        </div>
    </Modal>
}