import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faClipboard } from '@fortawesome/free-solid-svg-icons'

import './ModalLinkToCliente.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useTranslate from '../../utils/translate';

export default function ModalLinkToCliente({id, codigo, isOpen, toggleModal}){


    const t = useTranslate();

    const copyToClipboard = (str) => {
        navigator.clipboard.writeText(str);
        toast(t({
            es: '✅ Copiado al portapapeles!',
            en: '✅ Copied to clipboard!'
        }), {
            theme: "dark",
            autoClose: 1000,
        });
    };
    
    
    return <Modal
        centere
        isOpen={isOpen}
        toggle={toggleModal}
    >

        <ToastContainer/>
        <ModalHeader toggle={toggleModal}>
            {t({
                es: 'Entrégale esta información a tu cliente',
                en: 'Give this information to your client'
            })}
        </ModalHeader>
        <ModalBody>
            <input hidden value={'https://pickalbum.com/select/'+id} type="text" onChange={()=>{}} id='input_gg'></input>
            <p>
                {t({
                    es: 'Para entrar a tu álbum de fotos y seleccionar las fotos que quieras primero debes entrar a este link',
                    en: 'To enter your photo album and select the photos you want, you must first enter this link'
                })}
            </p>
            <p>
                <strong> <span className='spanLink'>{"https:/pickalbum.com/select/"+id}</span></strong>
                <MiniBtnCopy textToCopy={"https:/pickalbum.com/select/"+id} />
            </p>
            <p>
                {t({
                    es: 'Luego debes ingresar este código:',
                    en: 'Then you must enter this code:'
                })}
            </p>
            <p>
                <strong>{codigo}</strong>
                <MiniBtnCopy textToCopy={codigo} />
            </p>
            <span><strong>
                {t({
                    es: 'Cuidado! cualquiera con este código podrá ingresar y ver las fotos.',
                    en: 'Careful! anyone with this code will be able to enter and view the photos.'
                })}
            </strong></span>
            <br/><br/>
            <button onClick={()=>{copyToClipboard(
                t({
                    es: 'Para entrar a tu álbum de fotos y seleccionar las fotos que quieras primero debes entrar a este enlace'
                    +'\nhttps://pickalbum.com/select/'+id
                    +'\nLuego ingresar este código:'
                    +'\n'+codigo
                    +'\n*Cuidado! cualquiera con este código podrá ingresar y ver las fotos.*',
                    en: 'To enter your photo album and select the photos you want, you must first enter this link'
                    +'\nhttps://pickalbum.com/select/'+id
                    +'\nThen enter this code:'
                    +'\n'+codigo
                    +'\n*Careful! anyone with this code will be able to enter and view the photos.*'
                })
            )}} className="btn btn-primary">
                <span>
                    {t({
                        es: 'Copiar todo al portapapeles',
                        en: 'Copy all to clipboard'
                    })}
                </span>
                <FontAwesomeIcon className="" icon={faClipboard} />
            </button>
            <br/>
            <div className="alert alert-danger mt-2" role="alert">
                <span><strong>
                    {t({
                        es: 'Como fotografo no olvides recordarle a tu cliente que si las fotos son privadas no debe compartir el código.',
                        en: 'As a photographer, do not forget to remind your client that if the photos are private, they should not share the code.'
                    })}
                </strong></span>
                <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />
            </div>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggleModal}>
                {t({
                    es: 'Cerrar',
                    en: 'Close'
                })}
            </Button>
        </ModalFooter>
    </Modal>
    
}

const MiniBtnCopy = ({textToCopy}) => {

    const t = useTranslate();

    const copyToClipboard = (str) => {
        navigator.clipboard.writeText(str);
        toast(t({
            es: '✅ Copiado al portapapeles!',
            en: '✅ Copied to clipboard!'
        }), {
            theme: "dark",
            autoClose: 1000,
        });
    };


    return <button
        onClick={()=>{copyToClipboard(textToCopy)}}
        className="miniBtnCopy"
    >
        <FontAwesomeIcon className="" icon={faClipboard} />
    </button>
}



