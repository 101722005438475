import { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import './ModalShowSingleImg.css'
 
const ModalShowSingleImg = ({ urlImg, isOpen = false, toggleModal }) => {




    useEffect(() => {
        //desactivamos el scroll del body
        if(isOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }

    }, [isOpen]);

    if(!isOpen){
        return null;
    }
 
    return <>
        <div className='backgroundModalView' >
            <div className='contModalView' >
                        <div className='divBackground' onClick={toggleModal}>

                        </div>

                        <div className='contImg'>
                            <img className="" alt="Esta es la imagen que estamos visualizando" src={urlImg}/>
                            <a className='btnOpenImg' href={urlImg} target="_blank">
                                <span>Open Img </span>
                                <FontAwesomeIcon icon={faEye} />
                            </a>
                            <button className='btnCloseModalView' onClick={toggleModal}>
                                X
                            </button>
                        </div>
            </div>
        </div>
   
    </>
        
}

export default ModalShowSingleImg