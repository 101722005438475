import { useState, useEffect } from "react";
//import About from "./Components/About/About";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

import { Link } from "react-router-dom";

import InputMarcaAgua from "../Components/inputMarcasAgua/inputMarcaAgua";

import './../css/Home.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faPlay 
} from "@fortawesome/free-solid-svg-icons";

import useTranslate from "../utils/translate";
import TablePricing from "../Components/TablePricing/TablePricing";

 
export default function Home({loading, logged}){

    const [imagesPrueba, setimagesPrueba] = useState([]);
    const t = useTranslate();

    const [openPopover, setopenPopover] = useState(false);
    const togglePopover = () => setopenPopover(!openPopover);
    

    useEffect(() => {
        if(window.innerWidth <= 768){
            if(localStorage.getItem("mensajeAdvertenciaCelularMostrado") !== "true"){
                alert(t({
                    es: "Para una mejor experiencia recomendamos usar esta herramienta en un dispositivo de escritorio o laptop.",
                    en: "For a better experience we recommend using this tool on a desktop or laptop device."
                }));
                //después de mostrar este mensaje no lo voleremos a mostrar, para eso guardaremos en el localstorage un valor que diga que ya se mostró el mensaje
                localStorage.setItem("mensajeAdvertenciaCelularMostrado", "true");
            }
        }else{
        }
    }, []);


    return <div className="bodyContainer">
        <Header loading={loading} logged={logged}/>
        <div className="container bodyConentFullHeight">

            <div className='panelHeader'>
                <div className="contHeader">
                    <div className='row rowContainer'>
                        <div className='columLeft contImgHeader col-md-6 col-lg-5 pt-2 pt-md-5'>
                            <img className='imgHeader' src={require('./../images/emptyImagesAlbum2.png')} alt="bannerimg"></img>
                        </div>
                        <div className='columRight col-md-6 col-lg-7 d-flex flex-column justify-content-center align-items-start pt-md-5 '>
                            <h1 className='tituloWeb'>PickAlbum.com</h1>
                            <p className='plus'>
                                <span className='iconStar'>
                                    <FontAwesomeIcon icon={faStar}/>{' '}
                                </span>
                                {
                                    t({
                                        es: "Ahorra tiempo a la hora de elegir imágenes, agrega marcas de agua y deja que los clientes elijan las que prefieren.",
                                        en: "Save time choosing images, add watermarks, and let customers choose the ones they prefer."
                                    })
                                }
                            </p>
                            <p className='descript'>
                                {t({
                                    es: "Sistema automático de marcas de agua para cientos de imágenes.",
                                    en: "Automatic watermark system for hundreds of images."
                                })}
                            </p>
                            <p className='descript'>
                                {t({
                                    es: "Sube imágenes en HD y envía un link a tus clientes para que puedan elegirlas.",
                                    en: "Upload HD images and send a link to your customers so they can choose them."
                                })}
                            </p>
                            <Link
                                to="/login"
                            >
                                <button className='btnComenzar mt-3 mt-md-4'>
                                    <span className='txtBtnComenzar'>{t({
                                        es: 'Comenzar',
                                        en: "Let's Start"
                                    })}</span>
                                    <span >
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </span>
                                </button>
                            </Link>
                        {/*  <p className='plus'>
                                <span className='icon'>
                                    <FontAwesomeIcon icon={faStar}/>{' '}
                                </span>
                                Enseña tu trabajo a quien quieras creando tu portafolio online con galerías de imágenes públicas.
                            </p>
                            <p className='descript'>Una página web donde podrás enseñar tu trabajo e información de contacto. </p>
                            <button className='verEjem'>
                                Ver Ejemplo
                                {' '}
                                <FontAwesomeIcon icon={faEye}/>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <br/>

            <div id="pruebaMarca" className="contPruebaMarca">
                <h4 href="#pruebaMarca" className="titleinvitacionprueba">
                    <span>
                        {t({
                            es: "Prueba nuestra herramienta",
                            en: "Try our tool"
                        })}
                    </span>
                </h4>
                <InputMarcaAgua images={imagesPrueba} setimages={setimagesPrueba} modoPrueba={true}/>
            </div>

            <br/>

            <TablePricing/>

            <br/>
            <br/>
            <br/>
        </div>
        <Footer/>
{/* 
        <div className='panelVideo'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 colLeft'>
                        <div>
                            <p className='firstP p-lg-3'>Esperamos ser una herramienta que ayude en el alcance, las labores diarias y los derechos de autor de la comunidad de fotógrafos.</p>
                            <p className='secP'>Todo completamente gratis!!</p>
                        </div>
                        <div>
                            <p className='dudas'>Si tienes dudas observa nuestro video instructivo:</p>
                            <a className='verVideo' href='https://www.youtube.com/watch?v=QN7Tcyg2AvA' target="_blank">
                                Ver video
                                {' '}
                                <FontAwesomeIcon className='icon' icon={faPlay}/>
                            </a>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div className='col-md-8 colRight'>
                        <div className='contVideo'>
                            <iframe className='video' src="https://www.youtube.com/embed/HHfAYIlgXBU?autoplay=0&mute=0&showinfo=0&controls=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>

                </div>
            </div>
        </div>
 */}
    </div>
}