import React, { useState } from 'react';
import PopoverCapacidad from './PopoverCapacidad/PopoverCapacidad';

import useTranslate from '../../utils/translate';

//css
import './TablePricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const TablePricing = () => {

    const t = useTranslate();

    return (
        <div className='contTablePricin' id="pricing" >
            <br/>
            <br/>
            <br/>
            <h2 className='titleSection'>Pick a Plan</h2>
            <p className='descripSection'>
                {t({
                    es: "⭐ Aprovecha nuestros precios de lanzamiento, no te quedes sin tu plan ⭐",
                    en: "⭐ Take advantage of our launch prices, don't miss out on your plan ⭐"
                })}
            </p>
            <hr className='hrHeadSection' />

            <table className='tablePricing noResponsive'>
                <thead>
                    <tr>
                        <th className='namePlan'></th>
                        <th className='namePlan'>Free</th>
                        <th className='namePlan'>Standar</th>
                        <th className='namePlan'>Plus</th>
                        <th className='namePlan'>Business</th>
                    </tr>
                    <tr>
                        <th className='price'></th>
                        <th className='price'>
                            <sup className='textPrice'>$</sup>
                            <span className='numberPrice'>0</span>
                            <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                        </th>
                        <th className='price'>
                            <sup className='textPrice'>$</sup>
                            <span className='numberPrice'>5</span>
                            <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                        </th>
                        <th className='price'>
                            <sup className='textPrice'>$</sup>
                            <span className='numberPrice'>12</span>
                            <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                        </th>
                        <th className='price'>
                            <sup className='textPrice'>$</sup>
                            <span className='numberPrice'>30</span>
                            <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='titleFeature'>
                            <span>
                                {t({
                                    es: "Capacidad ",
                                    en: "Capacity "
                                })}
                            </span>
                            <PopoverCapacidad/>
                        </td>
                        <td className="valorFeature">
                            1 GB
                            <br/>
                            <span className='textCapacidad'>(5k - 18k imgs)</span>
                        </td>
                        <td className="valorFeature">
                            10 GB
                            <br/>
                        </td>
                        <td className="valorFeature">
                            30 GB
                            <br/>
                        </td>
                        <td className="valorFeature">
                            100 GB
                            <br/>
                        </td>
                    </tr>
                    <tr>
                        <td className='titleFeature'>
                            <span>
                                {t({
                                    es: "Galerias",
                                    en: "Galleries"
                                })}
                            </span>
                        </td>
                        <td className="valorFeature">3</td>
                        <td className="valorFeature">20</td>
                        <td className="valorFeature">60</td>
                        <td className="valorFeature">
                            {t({
                                es: "ilimitado",
                                en: "unlimited"
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td className='titleFeature'>
                            <span>
                                {t({
                                    es: "Soporte Online",
                                    en: "Online Support"
                                })}
                            </span>
                        </td>
                        <td className="valorFeature">
                            <FontAwesomeIcon icon={faCheck} className='iconCheckTable' />
                        </td>
                        <td className="valorFeature">
                            <FontAwesomeIcon icon={faCheck} className='iconCheckTable' />
                        </td>
                        <td className="valorFeature">
                            <FontAwesomeIcon icon={faCheck} className='iconCheckTable' />
                        </td>
                        <td className="valorFeature">
                            <FontAwesomeIcon icon={faCheck} className='iconCheckTable' />
                        </td>
                    </tr>
                    
                </tbody>

            </table>

            <div className='tablePricing pricesResponsive'>
                <div>
                    <h2 className='namePlan'>Free</h2>
                    
                    <p className='price'>
                        <sup className='textPrice'>$</sup>
                        <span className='numberPrice'>0</span>
                        <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                    </p>
                    
                            
                    <p className='titleFeature'>{t({es: "Capacidad ", en: "Capacity "})} <strong>1GB </strong> <PopoverCapacidad/> <br/> <span className='textCapacidad'>(5k - 18k images aprox)</span> </p>
                    
                    <p className='titleFeature'>{t({es: "Galerias", en: "Galleries"})} <strong>3</strong></p>
                    
                    <p className='titleFeature'>{t({es: "Soporte Online", en: "Online Support"})} ✅</p>
                </div>

                <hr/>
                <br/>

                <div>
                    <h2 className='namePlan'>Standar</h2>
                    
                    <p className='price'>
                        <sup className='textPrice'>$</sup>
                        <span className='numberPrice'>5</span>
                        <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                    </p>
                    
                    <p className='titleFeature'>{t({es: "Capacidad", en: "Capacity"})} <strong>10 </strong> <PopoverCapacidad/>  </p>
                    
                    <p className='titleFeature'>{t({es: "Galerias", en: "Galleries"})} <strong>20</strong></p>
                    
                    <p className='titleFeature'>{t({es: "Soporte Online", en: "Online Support"})} ✅</p>
                </div>

                <hr/>
                <br/>

                <div>
                    <h2 className='namePlan'>Plus</h2>
                    
                    <p className='price'>
                        <sup className='textPrice'>$</sup>
                        <span className='numberPrice'>12</span>
                        <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                    </p>
                    
                    <p className='titleFeature'>{t({es: "Capacidad", en: "Capacity"})} <strong>30 </strong> <PopoverCapacidad/>  </p>
                    
                    <p className='titleFeature'>{t({es: "Galerias", en: "Galleries"})} <strong>60</strong></p>
                    
                    <p className='titleFeature'>{t({es: "Soporte Online", en: "Online Support"})} ✅</p>
                </div>

                <hr/>
                <br/>

                <div>
                    <h2 className='namePlan'>Business</h2>
                    
                    <p className='price'>
                        <sup className='textPrice'>$</sup>
                        <span className='numberPrice'>30</span>
                        <span className='textPrice'>{t({es: "/mes", en: "/month"})}</span>
                    </p>
                    
                    <p className='titleFeature'>{t({es: "Capacidad", en: "Capacity"})} <strong>100 </strong> <PopoverCapacidad/>  </p>
                    
                    <p className='titleFeature'>{t({es: "Galerias", en: "Galleries"})} <strong>{t({es: "ilimitado", en: "unlimited"})}</strong></p>
                    
                    <p className='titleFeature'>{t({es: "Soporte Online", en: "Online Support"})} ✅</p>
                </div>
                
            </div>
            <br/>
        </div>
    );
};

export default TablePricing;