import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import ModalLoading from '../Components/ModalLoading/ModalLoading';
import Footer from "../Components/Footer/Footer";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLock,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import Photographer from "../images/photo.svg";
import Google from "../images/google.png";
import Facebook from "../images/facebook.png";

import '../css/Login.css'


import { loginUser, loginWithGoogle, loginWithFacebook } from '../services/services';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
import { useAppContext } from '../context/AppContext';

import useTranslate from '../utils/translate';
import imgIconTranslate from '../images/translate.png';



/*-------------------------------------------------LOGIN------------------------------------------------------*/ 
export default function Login(){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  
    const [loading, setloading] = useState(false);

    const [context, setContext] = useAppContext();

    const t = useTranslate();
    const toggleTransalte = () => {
      setContext({...context, language: context.language === "es" ? "en" : "es"});
    };

    //la verificación de logueo se hace desde el onAuthStateChanged de firebase en el App.js y valida las pantallas que se muestran en el react-router-dom
    const handleSubmit = (e) => {
  
      setloading(true);
  
      e.preventDefault();
      
      loginUser({email, password}).then((res) => {
        console.log(res);
        if(!res.auth){
          setloading(false);
          toast('⚠️ ' + t({es: res.errorMessageUserEs, en: res.errorMessageUserEn}), {
            theme: "dark",
            autoClose: 3000,
          });
        }
      });
        
    };


    const handleGoogleLogin = () => {
      loginWithGoogle().then((res) => {
        console.log(res);
        if(!res.auth){
          setloading(false);
          toast('⚠️ ' + t({es: res.errorMessageUserEs, en: res.errorMessageUserEn}), {
            theme: "dark",
            autoClose: 3000,
          });
        }
      });
    };

    const handleFacebookLogin = () => {
      loginWithFacebook().then((res) => {
        console.log(res);
        if(!res.auth){
          setloading(false);
          toast('⚠️ ' + t({es: res.errorMessageUserEs, en: res.errorMessageUserEn}), {
            theme: "dark",
            autoClose: 3000,
          });
        }
      });
    };


  
    return <div >
  
      <ToastContainer/>
      <div className="l-form">
        <div className="shape1"></div>
        <div className="shape2"></div>
        
        <div className="form">
            
            <img src={Photographer} alt="" className="form__img" />
           
           <div className='columnRightLogin'>

          <div className='cont_form_title'>
            <img src={imgIconTranslate} alt="" className="form__imgTranslate" onClick={toggleTransalte} />
            <h1 className="form__title">{t({es: 'Bienvenido!', en: 'Welcome!'})}</h1>
          </div>

            <div className="form__social">
              <span className="form__social-text">
                {t({
                  es: 'Ingresa con un solo click:',
                  en: 'Login with one click:',
                })}
              </span>

              <a href='#' onClick={handleGoogleLogin} >
                <div className='contBtnSocial' style={{backgroundColor: '#dbdbdb'}}>
                  <img src={Google} alt="" className="imgIconSocial" />
                  <span className="textSocialBtn" style={{color: '#6e6e6e'}}>
                    {t({
                      es: 'Ingresa con Google',
                      en: 'Login with Google',
                    })}
                  </span>                
                </div>
              </a>

              {/* https://react-fotografos.firebaseapp.com/__/auth/handler */}
              <a href='#' onClick={handleFacebookLogin} >
                <div className='contBtnSocial' style={{backgroundColor: '#3b5998'}}>
                  <img src={Facebook} alt="" className="imgIconSocial" />
                  <span className="textSocialBtn" style={{color: 'white'}}>
                    {t({
                      es: 'Ingresa con Facebook',
                      en: 'Login with Facebook',
                    })}
                  </span>
                </div>
              </a>

            </div>


            <form action="" className="form__content pt-4" onSubmit={handleSubmit}>
  
              <span className='form__social-text'>
                {t({
                  es: 'Ingresa con tu correo y contraseña:',
                  en: 'Login with your email and password:',
                })}
              </span>
              <div className="form__div form__div-one">
                <div className="form__icon">
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
  
                <div className="form__div-input">
                  <input
                    type="email"
                    className="form__input"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder={
                      t({
                        es: 'Correo electrónico',
                        en: 'Email',
                      })
                    }
                    autoComplete={"true"}
                  />
                </div>
              </div>
  
              <div className="form__div">
                <div className="form__icon">
                  <FontAwesomeIcon icon={faLock} />
                </div>
  
                <div className="form__div-input">
                  <input
                    type="password"
                    className="form__input"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    autoComplete={"true"}
                    placeholder={
                      t({
                        es: 'Contraseña',
                        en: 'Password',
                      })
                    }
                  />
                </div>
              </div>
              <Link to="/register" className="form__forgot">
                {t({
                  es: '¿No tienes una cuenta? Registrate',
                  en: 'Do not have an account? Register',
                })}
              </Link>
  
              <input type="submit" className="form__button" value="Ingresar" />

            </form>
           </div>



  
          </div>
        <ModalLoading isOpen={loading}/>
  
      </div>
          <Footer></Footer>
    </div>
  }
  
  