import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './ViewImgs.css'

const ViewImgs = ({ arrayImgs = [], indexImgSelected = 0, setindexImgSelected, isOpen = false, toggleModal, modoSelected = false , selectImg }) => {

    const [zoomActived, setzoomActived] = useState(false);

    useEffect(() => {
        console.log(arrayImgs);
    }, [arrayImgs]);

    const [urlImgSelectd, seturlImgSelectd] = useState("");

    useEffect(() => {
        arrayImgs.length > 0 && seturlImgSelectd(arrayImgs[indexImgSelected].url)
    }, [indexImgSelected]);

    useEffect(() => {
        //desactivamos el scroll del body
        if(isOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);


    const goRight = () => {
        if (indexImgSelected === arrayImgs.length - 1) {} else {
            setindexImgSelected(indexImgSelected + 1);
        }
    }

    const goLeft = () => {
        if (indexImgSelected === 0) {} else {
            setindexImgSelected(indexImgSelected - 1);
        }
    }

    //aquí detectaremos la presion de las teclas izquierda y derecha y ejecutaremos las funciones goRight y goLeft
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 39) {
                goRight();
            }
            if (e.keyCode === 37) {
                goLeft();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [indexImgSelected]);



    if(!isOpen){
        return null;
    }

    return <>
        <div className='backgroundModalView' >
            <div className='contModalView' >
                        <div className='divBackground' onClick={toggleModal}>

                        </div>

                        {
                            indexImgSelected !== 0 &&
                            <div className='contBtnArrow contBtnLeft'>
                                <button onClick={goLeft} disabled={indexImgSelected === 0}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            </div>

                        }
                        {
                            indexImgSelected !== arrayImgs.length - 1 &&
                            <div className='contBtnArrow contBtnRight'>
                                <button onClick={goRight} disabled={indexImgSelected === arrayImgs.length - 1}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        }
                        <div className='contImg'>
                            <img 
                            className={zoomActived ? 'zoomImg' : ''} 
                            alt={arrayImgs[indexImgSelected].name} 
                            src={urlImgSelectd} 
                            onClick={()=>{{
                                setzoomActived(!zoomActived);
                            }}}/>
                            <div className='contInferior'>
                                {/* <a className='btnOpenImg' href={urlImgSelectd} target="_blank">
                                    <span>Open Img </span>
                                    <FontAwesomeIcon icon={faEye} />
                                </a> */}
                                <div className='contspanNameImg'>
                                    <span className='spanNameImg'>
                                        {arrayImgs[indexImgSelected].name}
                                    </span>
                                </div>
                                {
                                    modoSelected === true &&
                                    <label className='btnSelecteImg'>
                                        <span>select img</span>
                                        <input className='inputCheck' type='checkbox' checked={arrayImgs[indexImgSelected].seleccionado} onChange={(e) => {
                                            selectImg(e.target.checked, arrayImgs[indexImgSelected].name)
                                        } }/>
                                    </label>
                                }
                            </div>
                            <div className='contBtnCLose'>
                                <button className='btnCloseModalViewImgs' onClick={toggleModal}>
                                    X
                                </button>

                            </div>
                        </div>
            </div>
        </div>
   
    </>
        
}

export default ViewImgs