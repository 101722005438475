import React, {useState, useEffect} from 'react';

import { getSesionByFotografoAndCodigo, sendSelectionImgs } from '../../services/services';

import './selectMyFotos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewImgs from '../ViewImgs/ViewImgs';

import useTranslate from '../../utils/translate';
 
export default function SelectMyFotos(props){
    const [codigoSesion, setcodigoSesion] = useState("");
    const [idSesion, setidSesion] = useState();
    const [archivos, setarchivos] = useState([]);
    const [estadoIngreso, setestadoIngreso] = useState(3);
    const [nameSesion, setnameSesion] = useState();

    const t = useTranslate();

    const funcSetCodigoSesion = (e)=>{
        //nos asegurarmos que no se pueda ingresar un codigo de mas de 6 caracteres
        if(e.target.value.length > 6){
            return;
        }
        //nos aseguramos que no se pueda ingresar espacios en blanco
        if(e.target.value.includes(" ")){
            return;
        }
        //nos aseguramos que solo se pueda ingresar numeros
        if(isNaN(e.target.value)){
            return;
        }

        estadoIngreso === 2 && setestadoIngreso(3);
        setcodigoSesion(e.target.value);
    }

    
    const getDataSesion = async ()=> {

        getSesionByFotografoAndCodigo(props.idFotografo, codigoSesion).then(res=>{
            console.log(res);
            if(!res.data){
                setestadoIngreso(2)
            }else{
                setestadoIngreso(1)
                setarchivos(res.data.archivos);
                setnameSesion(res.data.categoria);
                setidSesion(res.data._id);
            }
        });
        
    }

        //const [arraySelected, setarraySelected] = useState([]);
 
    
    const selectImg = (checked, archivo)=>{
    
        let newData = [];
        archivos.forEach(arch=>{
            if(arch.name === archivo){
                newData.push({...arch, seleccionado: checked});
            }else{
                newData.push(arch);
            }
        });

        setarchivos(newData);
    
    } 


    const enviarSeleccion = async ()=>{
        
        var totalSeleccionados = 0;
        archivos.forEach(arch => {
            if(arch.seleccionado){
                totalSeleccionados++;
            }
        });
        //console.log(totalSeleccionados);
        if(totalSeleccionados < 1){
            toast(
                t({
                    es: "Debes seleccionar al menos una foto",
                    en: "You must select at least one photo"
                }), 
                {
                    theme: "dark",
                    autoClose: 3000,
                }
            );
            return;
        }

        sendSelectionImgs(idSesion, archivos).then(res=>{
            if(res.data){
                toast(
                t({
                    es: '✅ Selección enviada a tu fotografo!. Si deseas hacer cambios solo hazlo de nuevo', 
                    en: '✅ Selection sent to your photographer!. If you want to make changes just do it again'
                }),
                    {
                    theme: "dark",
                    autoClose: 4000,
                });
            }else{
                toast('⚠️ '+ t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}), {
                    theme: "dark",
                    autoClose: 1500,
                });
            }
        });      

    }
    

    const [imgPreview, setimgPreview] = useState('');

    const [isOpenModalPreviewImg, setisOpenModalPreviewImg] = useState(false)
    function toggleModalPreviewImg(){
        setisOpenModalPreviewImg(!isOpenModalPreviewImg);
    }

    function setImgToPreView(index){
        setimgPreview(index);
        toggleModalPreviewImg();
    }


    //aquí vamos a desactivar el click derecho del mouse, solo si estamos en modo seleccionar
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        }
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        }
    }, []);

        


    if(estadoIngreso === 1){
        return <div className="container py-4">
            <ToastContainer/>
            <h3 className="text-center text-capitalize">{nameSesion}</h3>
            <h4 className="text-center">
                {t({
                    es: "Selecciona las fotos que desees",
                    en: "Select the photos you want"
                })}
            </h4>
            <hr/>
            <div className="row">
                {archivos.map((archivo, index) => 
                    <div className="col-6 col-sm-4 col-lg-3 square" key={index}>
                        <div className="content_img">
                            <input 
                                type="checkbox" 
                                className="position-absolute inputSelectImgClient"  
                                checked={archivo.seleccionado} 
                                onChange={(e)=>{selectImg(e.target.checked, archivo.name)}}/>
                            <img src={archivo.url} className="img-responsive cursor-zoom" onClick={()=>{setImgToPreView(index)}} data-toggle="modal" data-target="#ModalPreViewImgEdit" ></img>
                        </div>
                    </div> 
                )}
            </div>
            <button className=" btn btn-success mt-2" style={{float: 'right'}} onClick={enviarSeleccion}>
                <span>
                    {t({
                        es: "Enviar selección ",
                        en: "Send selection "
                    })}
                </span>
                <FontAwesomeIcon
                    icon={faCheck}
                />
            </button>
            <ViewImgs
                arrayImgs={archivos}
                indexImgSelected={imgPreview}
                setindexImgSelected={setimgPreview}
                isOpen={isOpenModalPreviewImg}
                toggleModal={toggleModalPreviewImg}
                selectImg={selectImg}
                modoSelected={true}
            />

        </div>
    }else{
        return <div className="container d-flex flex-column align-items-center">
            <h4>
                {t({
                    es: "Ingresa el código de tu álbum",
                    en: "Enter the code of your album"
                })}
            </h4>
            <input type="text" className="mt-1 mb-1" value={codigoSesion} onChange={(e)=>{funcSetCodigoSesion(e)}}></input>
            <button className="btn btn-primary" onClick={getDataSesion}>{t({es: 'Aceptar', en: 'Accept'})}</button>
            <MsgStatusCodigo estadoIngreso={estadoIngreso}/>
        </div>
    }

}

const MsgStatusCodigo = ({estadoIngreso})=>{

    const t = useTranslate();
    
    if(estadoIngreso === 2){
        return <span className='text-danger'>
            {t({
                es: "El código ingresado es incorrecto o no existe",
                en: "The entered code is incorrect or does not exist"
            })}
        </span>
    }else{
        return null;
    }
}




