import { firebaseApp } from "../utils/firebase";
import { 
    getAuth, 
    signOut, 
    signInWithPopup,
    getAdditionalUserInfo,
    GoogleAuthProvider, 
    FacebookAuthProvider, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
} from "firebase/auth";

import { getFirestore, collection, doc, setDoc, updateDoc, getDoc, getDocs, where, query, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject, getDownloadURL, uploadBytes } from "firebase/storage";

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const evaluarLogueo = () => {
    if(auth.currentUser){
        console.log('ya estas logueado');
        return true;
    }else{
        console.log('no estas logueado');
        return false;
    }
};

const getMsgLoginErrorByCode = (errorCode) => {
    let errorMessageUserEs = '';
    let errorMessageUserEn = '';
    switch (errorCode) {
        case 'auth/user-not-found':
            errorMessageUserEs = 'El usuario no existe';
            errorMessageUserEn = 'Username does not exist';
            break;
        case 'auth/wrong-password':
            errorMessageUserEs = 'Usuario o contraseña incorrectos';
            errorMessageUserEn = 'Wrong email/password';
            break;
        default:
            errorMessageUserEs = 'Error al iniciar álbum';
            errorMessageUserEn  = 'failed to login';
            break;
    }
    return [errorMessageUserEs, errorMessageUserEn];
};

const createDocNewUser = async (user) => {
    try {
        await setDoc(doc(db, "fotografos", user.uid), {
            marcaAgua: false,
            privateEmail: user.email,
            createdAt: new Date(),
        });
        return true;
        
    } catch (error) {
        console.log('error al crear doc user '+error);
        return false;
    }
};

export const registerUser = async ({correo, contraseña}) => {

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, correo, contraseña);

        //aquí creamos el documento del usuario o fotografos
        const ok = await createDocNewUser({uid: userCredential.user.uid, email: correo});

        return {auth: true, message: 'Usuario creado correctamente', dataUser: userCredential.user};

    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        let errorMessageUserEs = '';
        let errorMessageUserEn = '';
        switch (errorCode) {
            case 'auth/email-already-in-use':
                errorMessageUserEs = 'El correo electrónico ya está en uso por otra cuenta.';
                errorMessageUserEn = 'The email address is already in use by another account.';
                break;
            case 'auth/invalid-email':
                errorMessageUserEs = 'El correo electrónico no es válido.';
                errorMessageUserEn = 'The email address is badly formatted.';
                break;
            case 'auth/operation-not-allowed':
                errorMessageUserEs = 'La creación de usuarios con correo electrónico y contraseña no está habilitada.';
                errorMessageUserEn = 'Password sign-in is disabled for this project.';
                break;
            case 'auth/weak-password':
                errorMessageUserEs = 'La contraseña debe tener al menos 6 caracteres.';
                errorMessageUserEn = 'The password must be 6 characters long or more.';
                break;
            default:
                errorMessageUserEs = 'Error al crear el usuario, por favor intente nuevamente.';
                errorMessageUserEn  = 'Error creating user, please try again.';
                break;
        }
        return {auth: false, message: errorMessage, errorCode: errorCode, errorMessageUserEs,  errorMessageUserEn};
    }
};

export const loginUser = async ({email, password}) => {
    console.log('loginUser' + email + password);
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('no llegamos aquí');
       /*  if (window.PasswordCredential) {
            var c = await navigator.credentials.create({password: e.target});
            return navigator.credentials.store(c);
          } else {
            return Promise.resolve(profile);
          }
        return {auth: true, message: 'Usuario logueado correctamente', dataUser: userCredential.user}; */
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        let [errorMessageUserEs, errorMessageUserEn] = getMsgLoginErrorByCode(errorCode);
        return {auth: false, message: errorMessage, errorCode: errorCode, errorMessageUserEs, errorMessageUserEn};
    }

};

export const logOut = async () => {

    try {
        const res = await signOut(auth);
        return {auth: true, message: 'Usuario deslogueado correctamente'};
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        return {auth: false, message: errorMessage};        
    }

};




export const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {


        const res = await signInWithPopup(auth, provider);
        console.log(res);

        //aquí evaluamos si el usuario es nuevo
        const {isNewUser} = getAdditionalUserInfo(res);
        if(isNewUser){
            //si es nuevo lo creamos en la colección de fotografos
            console.log('El usuario es nuevo');
            const ok = await createDocNewUser({uid: res.user.uid, email: res.user.email});
        }

        return {auth: true, message: 'Usuario logueado correctamente', dataUser: res.user};

    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        let errorMessageUserEs = 'Error al iniciar sesión con Google';
        let errorMessageUserEn = 'Error logging in with Google';
        if(errorCode === 'auth/account-exists-with-different-credential'){
            errorMessageUserEs = 'Ya existe una cuenta con el mismo correo electrónico pero con diferente proveedor(Google o Facebook).';
            errorMessageUserEn = 'An account already exists with the same email address but different sign-in credentials.';
        }
        return {auth: false, message: errorMessage, errorCode: errorCode, errorMessageUserEs, errorMessageUserEn};        
    }
};

export const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    
    try {

        const res = await signInWithPopup(auth, provider);
        console.log(res);

        //aquí evaluamos si el usuario es nuevo
        const {isNewUser} = getAdditionalUserInfo(res);
        if(isNewUser){
            //si es nuevo lo creamos en la colección de fotografos
            console.log('El usuario es nuevo');
            const ok = await createDocNewUser({uid: res.user.uid, email: res.user.email});
        }

        return {auth: true, message: 'Usuario logueado correctamente', dataUser: res.user};

    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        let errorMessageUserEs = 'Error al iniciar sesión con Google';
        let errorMessageUserEn = 'Error logging in with Google';
        if(errorCode === 'auth/account-exists-with-different-credential'){
            errorMessageUserEs = 'Ya existe una cuenta con el mismo correo electrónico pero con diferente proveedor(Google o Facebook).';
            errorMessageUserEn = 'An account already exists with the same email address but different sign-in credentials.';
        }
        return {auth: false, message: errorMessage, errorCode: errorCode, errorMessageUserEs, errorMessageUserEn};        
    }
};













//DB FIRESTORE - FIREBASE / QUERIES

export const saveSesion = async ({nameSesion, images}) => {

    console.log(images);
    
    function eliminarEspaciosBlancos(texto){
        return texto.replace(/\s/g, "");
    }
    
    if(eliminarEspaciosBlancos(nameSesion).length < 1){
        // console.log("EL nombre no puede estar vacío");
        return {
            data: {
                auth: false,
                message: 'EL nombre no puede estar vacío',
                errorMessageUserEs: 'EL nombre no puede estar vacío',
                errorMessageUserEn: 'The name cannot be empty'
            }
        }
    }
    
    if(images.length < 1){
        // alert("Aún no has subido imágenes");
        return {
            data: {
                auth: false,
                message: 'Aún no has subido imágenes',
                errorMessageUserEs: 'Aún no has subido imágenes',
                errorMessageUserEn: 'You have not uploaded images yet'
            }
        }
    }


    //primero vamos a crear la referencia del documento sesión para así obtener su id
    const newSesionRef = doc(collection(db, "sesiones"));

    const idSesion = newSesionRef.id;
    // console.log(idSesion);

    const urlFiles = await uploadImagesAndGetUrls(images, idSesion);

    // console.log(urlFiles);

    const arrayFiles = [];

    urlFiles.forEach(img => {
        //aquí filtramos los valores finales que se guardaran en la DDBB
        arrayFiles.push({
            url: img.url,
            name: img.name,
            marcaPuesta: img.marcaPuesta,
            index: img.index,
            size: img.size,
            seleccionado: false,
        });
    });

    //más tarde voy a hacer esto para sumar el peso de las imágenes al usuario
    function sumarPesoAlbumACuentaUsuario(pesoAlbum){
    }


    function getNumRandom(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    const codigo = getNumRandom(100000, 999999);


    const data = {
        _id: idSesion,
        idFotografo: auth.currentUser.uid,
        categoria: nameSesion,
        numProxiFiles: arrayFiles.length,
        archivos: arrayFiles,
        seleccionado: false,
        codigo: codigo.toString(),
        createdAt: new Date(),
    }
    console.log(data);

    try {
        await setDoc(newSesionRef, data);
        return { data:  { auth: true, message: 'Sesion guardada correctamente' } }
    }catch (error) {
        console.log(error);
        return { data:  { auth: false, errorMessageUserEs: 'Error al guardar la álbum', errorMessage: error, errorMessageUserEn: 'Error saving session' } }
    }

}


export const getSesionesFotografo = async () => {

    try {
        const idFotografo = auth.currentUser.uid;
        const sesionesRef = collection(db, "sesiones");
        const q = query(sesionesRef, where("idFotografo", "==", idFotografo));
        const querySnapshot = await getDocs(q);
        let sesiones = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            sesiones.push(doc.data());
        });
        return { data:  { auth: true, sesiones: sesiones } }
    }
    catch (error) {
        console.log(error);
        return { data:  { auth: false, errorMessageUserEs: 'Error al pedir los albumes', errorMessageUserEn: 'Error getting albumes', errorMessage: error } }
    }
    
};


export const getSesionById = async (idSesion) => {
    
        try {
            const sesionRef = doc(db, "sesiones", idSesion);
            const docSesion = await getDoc(sesionRef);
            if (docSesion.exists()) {
                return { auth: true, data: docSesion.data() }
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                return { auth: false, errorMessageUserEs: 'No existe la álbum', errorMessageUserEn: 'Album does not exist' }
            }
        }
        catch (error) {
            console.log(error);
            return { auth: false, errorMessageUserEs: 'Error al pedir la álbum', errorMessageUserEn: 'Error to get album', errorMessage: error }
        }
        
}


export const deleteImgsSesion = async (idSesion, arrayFilesToDelete) => {

    //aquí debemos primero evaluar que la cantidad de imágenes a eliminar no sea menor o igual a la cantidad de imágenes que tiene la sesión
    const docRef = doc(db, "sesiones", idSesion);
    const snapDoc = await getDoc(docRef);
    const actualDoc = snapDoc.data();

    if(arrayFilesToDelete.length >= actualDoc.archivos.length){
        return { data: { auth: false, errorMessageUserEs: 'No puedes eliminar todas las imágenes', errorMessageUserEn: "You can't delete all images" } }
    }

    console.log(arrayFilesToDelete);
    const ok = await deleteFilesStorage(arrayFilesToDelete);

    if(!ok){
        return { data: { auth: false, errorMessageUserEs: 'Error al eliminar las imágenes', errorMessageUserEn: 'Error deleting images' } }
    }

    //ahora debemos eliminar las imágenes del array de archivos de la sesión
    const actualArrayFiles = actualDoc.archivos;
    const newArrayFiles = []

    actualArrayFiles.forEach((file, index) => {
        if(!arrayFilesToDelete.includes(file.url)){
            newArrayFiles.push(file);
        }
    });

    console.log("newArrayFiles: ");
    console.log(newArrayFiles);

    try{
    
        //ahora debemos actualizar el documento de la sesión
        await updateDoc(docRef, {
            archivos: newArrayFiles,
        });

        return { data: { auth: true } }

    }catch(error){
        console.log(error);
        return { data: { auth: false, errorMessageUserEs: 'Error al eliminar las imágenes',errorMessageUserEn: 'Error deleting images' , errorMessage: error } }
    }

}


export const addImgsToSesion = async (idSesion, arrayFilesToAdd) => {

    //primero obtendremos el valor numProxiFile del documento de la sesión
    const docRef = doc(db, "sesiones", idSesion);
    const snapDoc = await getDoc(docRef);
    const actualDoc = snapDoc.data();
    const numProxiFile = actualDoc.numProxiFiles;

    //ahora subiremos las imágenes al storage
    const urlFiles = await uploadImagesAndGetUrls(arrayFilesToAdd, idSesion, numProxiFile);
    
    
    //ahora debemos actualizar el valor de numProxiFile y añadir las imágenes al array de archivos de la sesión
    const newArrayFiles = actualDoc.archivos;
    urlFiles.forEach(img => {
        newArrayFiles.push({
            ...img,
            seleccionado: false,
        });
    });

    try{
        
        //ahora debemos actualizar el documento de la sesión
        await updateDoc(docRef, {
            archivos: newArrayFiles,
            numProxiFiles: numProxiFile + urlFiles.length
        });

        return { data: { auth: true } }

    }catch(error){
        console.log(error);
        return { data: { auth: false, errorMessageUserEs: 'Error al añadir las imágenes',errorMessageUserEn: 'Error adding images' , errorMessage: error } }
    }


}


export const deleteSesion = async (idSesion) => {
    
    const sesionRef = doc(db, "sesiones", idSesion);
    const docSesion = await getDoc(sesionRef);
    const sesion = docSesion.data();
    
    //primero eliminamos las imágenes del storage
    const arrayUrlFiles = [];
    sesion.archivos.forEach(file => {
        arrayUrlFiles.push(file.url);
    });
    console.log(arrayUrlFiles);
    const ok = await deleteFilesStorage(arrayUrlFiles);

    if(!ok){
        return { data: { auth: false, errorMessageUserEs: 'Error al eliminar las imágenes', errorMessageUserEn: 'Error deleting images' } }
    }

    //ahora eliminamos el documento de la sesión
    try{
        await deleteDoc(sesionRef);
        return { data: { auth: true } }
    }catch(error){
        console.log(error);
        return { data: { auth: false, errorMessageUserEs: 'Error al eliminar la álbum', errorMessageUserEn: 'Error deleting album', errorMessage: error } }
    }

}


export const getSesionByFotografoAndCodigo = async (idFotografo, codigoSesion) => {
    
    try {
        
        const querySnapshot = await getDocs(query(collection(db, "sesiones"), where("idFotografo", "==", idFotografo), where("codigo", "==", codigoSesion)));
        const arrayDocs = [];
        querySnapshot.forEach((doc) => {
            arrayDocs.push(doc.data());
        });
    
        if(arrayDocs.length === 0){
            return { auth: false, errorMessageUserEs: 'No existe la álbum', errorMessageUserEn: 'Album does not exist' }
        }
    
        return { auth: true, data: arrayDocs[0] } 

    } catch (error) {
        console.log(error);
        return { auth: false, errorMessageUserEs: 'Error al pedir la álbum' ,errorMessageUserEn: 'Error getting album' , errorMessage: error }
    }

    
}


export const sendSelectionImgs = async (idSesion, arrayFiles) => {

    //en esta función lo que hacemos es actualizar las imágenes que el usuario ha marcado como seleccionadas. y definir la sesión como seleccionada

    try{
        const sesionRef = doc(db, "sesiones", idSesion);
        await updateDoc(sesionRef, {
            archivos: arrayFiles,
            seleccionado: true,
        });
        return { data: { auth: true} }

    }catch(error){
        console.log(error);
        return { data: { auth: false, errorMessageUserEs: 'Error al enviar las imágenes seleccionadas', errorMessageUserEn: 'Error sending selected images', errorMessage: error } }
    }


}



export const saveMarcaAgua = async (urlImg) => {

    if(!auth.currentUser){
        return { data: { auth: false, message: 'No estas autenticado', errorMessageUserEs: 'No estas autenticado', errorMessageUserEn: 'Your are not authenticated' } }
    }

    try {
        await updateDoc(doc(db, "fotografos", auth.currentUser.uid), {
            marcaAgua: true,
            urlMarcaAgua: urlImg
        });
    
        return { data: { auth: true } }
    } catch (error) {
        return { data: { auth: false, message: error.message, errorMessageUserEs: 'Error al guardar la marca de agua', errorMessageUserEn: 'Error saving watermark' } }
    }

}


export const getMarcaAgua = async ()=>{

    if(!auth.currentUser){
        return { data: { auth: false, message: 'No estas autenticado', errorMessageUserEs: 'No estas autenticado', errorMessageUserEn: 'Your are not authenticated' } }
    }

    try {
        const docRef = doc(db, "fotografos", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
    
        if(data.marcaAgua){
            return { data: { marcaAgua: true, urlMarcaAgua: data.urlMarcaAgua } }
        }else{
            return { data: { marcaAgua: false } }
        }     
    } catch (error) {
        console.log('errorr: ' + error);
        return { data: { auth: false, message: error.message, errorMessageUserEs: 'Error al obtener la marca de agua', errorMessageUserEn: 'Error getting watermark' } }
    }

   

}








 


//DB STORAGE - FIREBASE / QUERIES




const uploadImagesAndGetUrls = async (images, idAlbum, numProxiFile = 0) => {

    const arrayFiles = [];
    //a veces se eliminan archivos y para que no hayan problemas tienen que estar todos identificados con un numero de archivo
    let index = numProxiFile;

    await Promise.all(images.map(async (img) => {

        let nameFoto = idAlbum + '_' + index + '.jpg';
        //formDatos.append('photos', dataURLtoFile(img.url, nameFoto));
        console.log(nameFoto);

        index++;

        let file;
        if(img.imgBlobWithMarca === undefined){
            file = img.imgBlob;
        }else{
            file = img.imgBlobWithMarca;
        }

        console.log(file);

        let storageRef = ref(storage, 'sesiones/'+ nameFoto);

        try {
            let snapshot = await uploadBytes(storageRef, file);
            // console.log('imagen subida a storage');
            //después de subir la imagen a firebase, la funcion downloadURL nos devuelve la url de la imagen
            let downloadURL = await getDownloadURL(snapshot.ref);
            //ahora vamos a remplazar la url en la imagen del estado imágenes.

            arrayFiles.push({
                ...img,
                url: downloadURL,
            });

          } catch (error) {
            console.log('error al subir imágenes a storage: ');
            console.log(error);
          }
        
    }))

    return arrayFiles;

}


const deleteFilesStorage = async (arrayUrlFilesToDelete) => {

    
    await Promise.all(arrayUrlFilesToDelete.map(async (urlFile) => {
        // let nameFile = urlFile.split('%2F')[1].split('?')[0];
        // let storageRef = ref(storage, 'sesiones/'+ nameFile);
        let storageRef = ref(storage, urlFile);
        try {
            await deleteObject(storageRef);
            //console.log('imagen eliminada de storage');
        } catch (error) {
            console.log('error al eliminar imágenes de storage: ');
            console.log(error);
        }
    }))

    return true;
    
}

