import React, {useState, useEffect} from 'react';
import {useHistory, useParams  } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import ModalLoading from '../Components/ModalLoading/ModalLoading';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';

import '../css/AdminSesiones.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faLink, faSave, faEye, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'

import InputMarcaAgua from '../Components/inputMarcasAgua/inputMarcaAgua';
import ModalLinkToCliente from '../Components/ModalLinkToCliente/ModalLinkToCliente';

import { getSesionById, deleteImgsSesion, addImgsToSesion, deleteSesion } from '../services/services';
import ViewImgs from '../Components/ViewImgs/ViewImgs';

import { ToastContainer, toast } from 'react-toastify';

import useTranslate from '../utils/translate';


function ShowSeleccionada(props){
    if(props.seleccionada){
        return <div className="TagImgSelected">
                <span>Selected </span>
                <FontAwesomeIcon icon={faCheck} />
            </div>
    }else{
        return <div className="position-absolute"></div>
    }
}


function ButtonDeleteSelectedFiles(props){

    const t = useTranslate();

    if(props.haySelectedFiles){
        return <button onClick={props.deleteImg} className="btnPanelEditSesion">
            <span>
                {t({es: 'Borrar Archivos ', en: 'Delete Files '})}
            </span>
            <span className='spanNoWrap'>
                {t({es: 'Seleccionados ', en: 'Selected '})}
                <FontAwesomeIcon icon={faTrashAlt} />
            </span>
        </button>
    }else{
        return <button className="btnPanelEditSesion" disabled>
            <span>
                {t({es: 'Borrar Archivos ', en: 'Delete Files '})}
            </span>
            <span className='spanNoWrap'>
                {t({es: 'Seleccionados ', en: 'Selected '})}
                <FontAwesomeIcon icon={faTrashAlt} />
            </span>
        </button>
    }
}




export default function EditSesion({loading, logged}){
    const { idSesion } = useParams();

    const [idFotografo, setidFotografo] = useState(null);

    const [dataSesion, setdataSesion] = useState([]);
    const [archivos, setarchivos] = useState([]);


    const [nameSesion, setnameSesion] = useState('');

    const [codigoSesion, setcodigoSesion] = useState('');

    const t = useTranslate();

    const history = useHistory();
    
    const [isOpenModalLoading, setisOpenModalLoading] = useState(false)


    const [imgToPreview, setimgToPreview] = useState('');
    const [showModalPreview, setshowModalPreview] = useState(false)
    function toggleModal(){
        setshowModalPreview(!showModalPreview);
    }

    function previewImg(index){
        setimgToPreview(index);
        toggleModal();
    }



const getDataSesion = ()=> {
    getSesionById(idSesion).then(res => {
        console.log(res.data);
        setdataSesion(res.data);
        setarchivos(res.data.archivos);
        setnameSesion(res.data.categoria);
        setcodigoSesion(res.data.codigo);
        setidFotografo(res.data.idFotografo);
    })
}

const deleteImg = async ()=>{
    if(window.confirm(t({
        es: 'Estás seguro que deseas eliminar las imágenes seleccionadas?',
        en: 'Are you sure you want to delete the selected images?'
    }))){

        function getUrlsArchivosSelectedAdmin(){
            let urls = [];
            archivos.forEach(archivo => {
                if(archivo.selectedAdmin){
                    urls.push(archivo.url);
                }
            });
            return urls;
        }

        const urlsToDelete = getUrlsArchivosSelectedAdmin();

        deleteImgsSesion(idSesion, urlsToDelete).then(res => {
            if(res.data.auth){
                // alert(res.data.message);
                getDataSesion();
            }else{
                alert(t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}));
                // history.push("/admin");
            }
        })
    }    
}

/* const selectImg = (checked, archivo)=>{

    if(checked){
        addItemTomArrState(archivo);
    }else{
        removeItemFromArrState(archivo);
    }

    function removeItemFromArrState ( item ) {

        if(arraySelected.length === 1){
            sethaySelectedFiles(false);
        }
        
        setarraySelected( arraySelected.filter( function( e ) {
            return e !== item;
        } ) );
    }

    function addItemTomArrState ( item ) {

        if(arraySelected.length === 0){
            sethaySelectedFiles(true);
        }

        setarraySelected( [...arraySelected, item] );
        
    }


    if(arraySelected.length > 0){
        sethaySelectedFiles(true);
    }else{
        sethaySelectedFiles(false);
    }

    //console.log(arraySelected);

}   */ 


const [contadorHaySelectedAdminFiles, setcontadorHaySelectedAdminFiles] = useState(0);

useEffect(()=>{
    let contador = 0;
    archivos.forEach(archivo => {
        if(archivo.selectedAdmin){
            contador++;
        }
    });
    setcontadorHaySelectedAdminFiles(contador);
}, []);

const selectImgAdmin = (checked, index)=>{


    let file = archivos.filter(archivo => archivo.index === index)[0];

    file = {...file, selectedAdmin: checked};

    const newArrayArchivos = archivos.map(archivo => {
        if(archivo.index === index){
            return file;
        }else{
            return archivo;
        }
    });

    setcontadorHaySelectedAdminFiles(checked ? contadorHaySelectedAdminFiles + 1 : contadorHaySelectedAdminFiles - 1);
    setarchivos(newArrayArchivos);

}

useEffect(()=>{
    getDataSesion();
}, []);


const [images, setimages] = useState([]);


function allImgsHaveMarca(){
    let allHaveMarca = true;
    images.forEach(img => {
        if(!img.marcaPuesta){
            allHaveMarca = false;
        }
    });
    return allHaveMarca;
}


//aquí agregamos más imágenes a este álbum, primero las subimos a firebase, y luego guardamos los archivos en el server mongoose
const guardar = async () => { 

    //esto es para advertir que no se han puesto marcas de agua a todas las imágenes
    if(!allImgsHaveMarca()){
        const sinMarcas = window.confirm("Algunas imágenes no tienen marca de agua, ¿Deseas continuar?");
        if(!sinMarcas){
            return;
        }
    }

    // alert('subiendo archivos, por favor espere');
    setisOpenModalLoading(true);
    
    addImgsToSesion(idSesion, images).then(res => {
    
        setisOpenModalLoading(false);

        if(res.data.auth){
            // alert(res.data.message);
            getDataSesion();
            setisOpenModalNewImgs(false);
            setimages([]);
        }else{
            alert(t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}));
            //history.push("/admin");
        }

    })

    
};



//---------------------------------------------------------------




function getFile(url, name){
    let body = url;
    //console.log(body);
    body = body.split(',')[1]
    const blob = new Blob([atob(body)], {
      type: 'image/jpg',
      encoding: 'utf-8'
    });
    const file = new File([blob], name)
    //console.log(file);
    return file;
}

function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    const file = new File([u8arr], filename, {type:mime});
    //console.log(file);
    return file;
}


const deleteThisSesion = async ()=>{
    setisOpenModalLoading(true);

    deleteSesion(idSesion).then(res => {
        if(res.data.auth){
            // alert(res.data.message);
            history.push("/panel");
        }else{
            alert(t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}));
        }
    })
   
    setisOpenModalLoading(false);

}


const [stringNamesFilesSelected, setstringNamesFilesSelected] = useState('');
const [showModalGetNamesSelectedFiles, setshowModalGetNamesSelectedFiles] = useState(false);
function toggleModalGetNamesSelectedFiles(){
    setshowModalGetNamesSelectedFiles(!showModalGetNamesSelectedFiles);
}
const showNamesSelectedFiles = ()=>{
    toggleModalGetNamesSelectedFiles();
    let names = '';
    archivos.forEach(archivo => {
        console.log(archivo.seleccionado);
        if(archivo.seleccionado){
            //agregamos el nombre del archivo pero omitimos la extensión
            names += archivo.name.split('.')[0] + ',';
        }
    });
    names = names.slice(0, -1);
    setstringNamesFilesSelected(names);
}





const [showModalDeleteAlbum, setshowModalDeleteAlbum] = useState(false)
function toggleModalDeleteAlbum(){
    setshowModalDeleteAlbum(!showModalDeleteAlbum);
}

const [isOpenModalNewImgs, setisOpenModalNewImgs] = useState(false)
function toggleModalNewImgs(){
    setisOpenModalNewImgs(!isOpenModalNewImgs);
}

const [isOpenModalLinkToClient, setisOpenModalLinkToClient] = useState(false)
function toggleModalLinkToClient(){
    setisOpenModalLinkToClient(!isOpenModalLinkToClient);
}




    return <div className='bodyContainer'>
        <Header loading={loading} logged={logged}/>
        <div className="container pt-3 pb-4 bodyConentFullHeight">
            <h3 className="text-center mt-3 mb-3 titleColor">{t({es: 'Álbum', en: 'Album'})} {nameSesion}"</h3>
            <div className="d-flex panelControl">
                <button data-toggle="modal" data-target="#AddFiles" className="btnPanelEditSesion " onClick={toggleModalNewImgs}>
                    <span>{t({es: 'Agregar más ', en: 'Add more '})}</span>
                    <span className='spanNoWrap'>
                        {t({es: 'imágenes ', en: 'images '})}
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                </button>
                <ButtonDeleteSelectedFiles haySelectedFiles={contadorHaySelectedAdminFiles > 0} deleteImg={deleteImg}></ButtonDeleteSelectedFiles>
                {/*<!-- Button trigger modal delete álbum-->*/}
                <button type="button" className="btnPanelEditSesion" onClick={toggleModalDeleteAlbum}>
                    <span>
                        {t({es: 'Eliminar ', en: 'Delete '})}
                    </span>
                    <span className='spanNoWrap'>
                        {t({es: 'álbum ', en: 'album '})}
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                </button>
                <button className="btnPanelEditSesion " onClick={toggleModalLinkToClient}>
                    <span>
                        {t({
                            es: 'Enlace para mi ', 
                            en: 'Link for my '
                        })}
                    </span>
                    <span className='spanNoWrap'>
                        {t({
                            es: 'cliente ',
                            en: 'client '
                        })}
                        <FontAwesomeIcon icon={faLink} />
                    </span>
                </button>
                <button disabled={!dataSesion.seleccionado} className="btnPanelEditSesion" onClick={showNamesSelectedFiles}>
                    <span>
                        {t({
                            es: 'Ver nombres de ', 
                            en: 'See file '
                        })}
                    </span>
                    <span className='spanNoWrap'>
                        {t({
                            es: 'archivos ',
                            en: 'names '
                        })}
                        <FontAwesomeIcon icon={faEye} />
                    </span>
                </button>
                
                <ModalLinkToCliente id={idFotografo} codigo={codigoSesion} toggleModal={toggleModalLinkToClient} isOpen={isOpenModalLinkToClient}></ModalLinkToCliente>

                {/*<!-- Modal delete this sesión-->*/}
                <Modal
                    centered
                    isOpen={showModalDeleteAlbum}
                    toggle={toggleModalDeleteAlbum}
                >
                    <ModalBody>
                        <h5>
                            {t({
                                es: '¿Estás seguro que deseas eliminar el álbum?',
                                en: '¿Are you sure you want to delete the album?'
                            })}
                        </h5>
                        <p>
                            {t({
                                es: 'Se eliminarán todas las imágenes y no podrás recuperarlas',
                                en: 'All images will be deleted and you will not be able to recover them'
                            })}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                            <button type="button" className="btnPanelEditSesion btn-secondary" onClick={toggleModalDeleteAlbum}>{
                                t({
                                    es: 'Cancelar',
                                    en: 'Cancel'
                                })
                            }</button>
                            <button type="button" className="btnPanelEditSesion btn-primary" data-dismiss="modal" onClick={deleteThisSesion}>{
                                t({
                                    es: 'Eliminar',
                                    en: 'Delete'
                                })
                            }</button>  
                    </ModalFooter>
                </Modal>
            </div>
            <div className="row">
                {/* 
                <div className="col-6 col-sm-4 col-lg-3" style={{backgroundColor: 'yellow'}}>a</div>
                <div className="col-6 col-sm-4 col-lg-3" style={{backgroundColor: 'blue'}}>a</div>
                <div className="col-6 col-sm-4 col-lg-3" style={{backgroundColor: 'red'}}>a</div>
                <div className="col-6 col-sm-4 col-lg-3" style={{backgroundColor: 'green'}}>a</div>
                */}
                {archivos.map((archivo, position) => 
                    <div className="col-6 col-sm-4 col-lg-3 square" key={archivo.index}>
                        <div className="content_img">
                            <input checked={archivo.selectedAdmin} type="checkbox" className="inputSelectImg" onChange={(e)=>{selectImgAdmin(e.target.checked, archivo.index)}}></input>
                            <ShowSeleccionada seleccionada={archivo.seleccionado} />
                            <img loading='lazy' src={archivo.url} className="img-responsive cursor-zoom" onClick={()=>{previewImg(position)}} data-toggle="modal" data-target="#ModalPreViewImgEdit" ></img>
                        </div>
                    </div> 
                )}
            </div>

            {/*Modal to add new files */}
            <Modal
                centered
                className='modal-xl'
                isOpen={isOpenModalNewImgs}
                toggle={toggleModalNewImgs}
            >
                <ModalHeader toggle={toggleModalNewImgs}>
                    <h5>
                        {t({
                            es: 'Agregar más imágenes al álbum ',
                            en: 'Add more images to the album '
                        })}
                        {'"' + nameSesion + '"'}
                    </h5>
                </ModalHeader>
                <ModalBody>
                        <InputMarcaAgua images={images} setimages={setimages}></InputMarcaAgua>
                        <button className="float-right btn btn-success" onClick={guardar}>
                            <span>
                                {t({
                                    es: 'Guardar ',
                                    en: 'Save '
                                })}
                            </span>
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleModalNewImgs}>
                        <span>
                            {t({
                                es: 'Cancelar ',
                                en: 'Cancel '
                            })}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
            <br></br>
            <ModalLoading isOpen={isOpenModalLoading}></ModalLoading>

            <br></br>
            <hr></hr>


            <ViewImgs 
                arrayImgs={archivos}
                indexImgSelected={imgToPreview}
                setindexImgSelected={setimgToPreview}
                isOpen={showModalPreview}
                toggleModal={toggleModal}
            />


                <Modal
                    centered
                    isOpen={showModalGetNamesSelectedFiles}
                    toggle={toggleModalGetNamesSelectedFiles}
                >
                    <ModalHeader toggle={toggleModalGetNamesSelectedFiles}>
                        <h5>
                            {t({
                                es: 'Nombres de archivos seleccionados',
                                en: 'Names of selected files'
                            })}
                        </h5>
                    </ModalHeader>
                    <ModalBody>
                        {stringNamesFilesSelected}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={()=>{
                            navigator.clipboard.writeText(stringNamesFilesSelected);
                            toast(t({
                                es: '✅ Copiado al portapapeles!',
                                en: '✅ Copied to clipboard!'
                            }), {
                                theme: "dark",
                                autoClose: 1000,
                            });
                        }}>
                            <span>
                                {t({
                                    es: 'Copiar al portapapeles ',
                                    en: 'Copy to clipboard '
                                })}
                            </span>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </ModalFooter>
                </Modal>

                <ToastContainer/>

        </div>
        <Footer/>
    </div>
}


