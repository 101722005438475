import React, {useState, useEffect} from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';

//hay un enredo en la importación de los estilos, y estoy imboccando muchos sin necestidad, pero la verdad por ahora arreglar esto no me importa
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/Cta.css";
import "./css/viewLoading.css";

import { Redirect } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Panel from './pages/Panel';

import SaveSesion from './pages/SaveSesion';
import editSesion from './pages/editSesion';
import ingresarCodigo from './pages/ingresarCodigo';
import WebSelectMyFotos from './pages/webSelectMyFotos';


import privacyPolicy from "./pages/privacyPolicy";
import deleteDataInfo from './pages/deleteDataInfo';

import { AppContext } from './context/AppContext';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
const auth = getAuth();

//aquí el sistema básico de login que hice para empezar, espero que sea mejorado por eso lo documentaré bien

//****tener en cuenta que primero no tenemos muchas pantallas de carga, esperemos que se puedan poner en proximas versiones
 

//esto serán como ua especie de variables globales que nos ayudarán establecer configuraaciones
//import {USER_KEY} from './confs/consts';


//este compt es el requireAuth, que sirve para establecer rutas protegidas, así por ejemplo validar que se haya logueado el usuario antes de entrar a alguna seccion, pero en la primera versión creo que solo validaré el logue con la ayuda de un useEfect en cada sección que evaluará si ya hay storage de logueo o no

function PrivateRoute({ logged, loading, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if(!loading){
          if (logged) {
            return <Component {...props} logged={logged} loading={loading} />;
          } else {
            return <Redirect to={"/login"} />;
          }
        }else{
          return <div className="viewLoading">
            <p>Cargando...</p>
          </div>
        }
      }}
    />
  );
}

//ahora crearemos una ruta personalizada solo para usuarios deslogueados
function UnLoggedRoute({ logged, loading, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if(!loading){
          if (logged) {
            return <Redirect to={"/panel"} />;
          } else {
            return <Component {...props} />;
          }
        }else{
          return <div className="viewLoading">
            <p>Cargando...</p>
          </div>
        }
      }}
    />
  );
}




export default function App(){

  const [loading, setloading] = useState(true);
  const [logged, setlogged] = useState(false);

  const [context, setcontext] = useState({
    user: {},
    sesiones: null,
    language: 'en'
  });

  function handleAuthStateChange(user) {
    //console.log("handleAuthStateChange", user);
    if (user) {
      setcontext({...context, user});
      setlogged(true);
    } else {
      setcontext({...context, user: {}});
      setlogged(false);
    }
    console.log('cambiando estado de autenticación');
    setloading(false);
  }

  useEffect(() => {

    //aquí nos estamos suscribiendo a los cambios de estado de autenticación cuando se inicia la app
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    //aquí nos desuscribimos de los cambios de estado de autenticación cuando el componente se desmonta
    return unsubscribe;

  }, []);


  //esta función se llama al comienzo de toda la aplicación para validar que existan las cookies de logueo, si existen validará que no se hayan caducado
  //cuando haya tiempo conectaré todo esto con useContext
  /* function validateLoggedIn(){ 
    console.log('validando logueo en app.js');



    
    if(!window.localStorage.getItem("TOKEN")){
      setlogged(false);
      setloading(false);
      console.log('no estaba logueado');
    }else{
      console.log('sí estaba logueado y ahora validaremos que la token no esté vencida');
      axios.get("https://api-fotografos.herokuapp.com/api/auth/getIsLogged",
      { 
        headers: {token: window.localStorage.getItem("TOKEN")}
      }
      )
      .then(res => {
          //console.log(res.data);
          if(!res.data.auth){
            console.log('la token está vencida, hace falta loguearce de nuevo');
            window.localStorage.removeItem("TOKEN");
            window.localStorage.removeItem("EMAIL");
            window.localStorage.removeItem("USER");
            window.localStorage.removeItem("ID_USER");
            setloading(false);
            setlogged(false);
          }else{
            console.log('la token no está vencida, todo está bien');
            setloading(false);
            setlogged(false);
          }
      });
    }
  } */


/* 
  useEffect(() => { 

    const queryParams = new URLSearchParams(window.location.search);

    if(!queryParams.get('_tkt') || !queryParams.get('_newUser')){
      console.log('no hay parametros de logueo google o facebook');
      validateLoggedIn(); 
    }else{

       //en ambos casos(si el usuario es nuevo o no) proveemos la token de logueo y luego en navegador evaluar que la debe usar para solicitar la verdaderá token
      console.log('solicitando token verdadera');
      const tkt = queryParams.get('_tkt');
      let newUser = queryParams.get('_newUser');

      axios.get("https://api-fotografos.herokuapp.com/api/auth/getTokenFromTkt",
      { 
        headers: {token: tkt}
      }
      )
      .then(res => {
          console.log(res.data);
          if(!res.data.auth){
            
            console.log('token invalida');
            window.localStorage.removeItem("TOKEN");
            window.localStorage.removeItem("EMAIL");
            window.localStorage.removeItem("USER");
            window.localStorage.removeItem("ID_USER");
            setloading(false);
            setlogged(false);
          }else{

            console.log('todo está correcto, logueado');

            window.localStorage.setItem("TOKEN", res.data.token);
            window.localStorage.setItem("AGENCIA", res.data.dataUser.nombreAgencia);
            window.localStorage.setItem("ID_USER", res.data.dataUser._id);

            //establecemos el estado como logueado;
            setloading(false);
            setlogged(true);


            history.push("/panel");
          
          }
      });

    }

  }, []) */


    return <div>
      <AppContext.Provider value={[context, setcontext]}>
        <BrowserRouter>

          {/* Este componente es para enviar el scroll arriba cada vez que pasamos de ruta */}
          <ScrollToTop />

          <Switch>
            
            <UnLoggedRoute logged={logged} exact path="/login" loading={loading} component={Login} />
            <UnLoggedRoute exact path="/register" loading={loading} logged={logged} component={Register}/>

            {/* Aquí estamos haciendo esto para pasar parametros al componente de la ruta */}
            <Route exact path="/">
              <Home loading={loading} logged={logged}/>
            </Route>  

            <PrivateRoute exact path="/panel" loading={loading} component={Panel} logged={logged} />  
            <PrivateRoute exact path="/saveSesion" loading={loading} component={SaveSesion} logged={logged} />  
            <PrivateRoute exact path="/editSesion/:idSesion" loading={loading} component={editSesion} logged={logged} />  

            <Route exact path="/ingresarCodigo" component={ingresarCodigo}/>  
            <Route exact path="/select/:idFotografo" component={WebSelectMyFotos}/>      

            <Route exact path="/privacyPolicy" component={privacyPolicy}/>  
            <Route exact path="/deleteDataInfo" component={deleteDataInfo}/>  

          </Switch>
        </BrowserRouter>
      </AppContext.Provider>
    </div>

}

/* 
        <Route
          path="/"
          render={props => <RequireAuth {...props} Component={Home} />}
        />
*/