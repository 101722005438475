import React, { useState } from 'react';

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import useTranslate from '../../../utils/translate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faLink } from '@fortawesome/free-solid-svg-icons';

import './PopoverCapacidad.css';

export default function PopoverCapacidad({}) {

    const [popoverCapacidadOpen, setpopoverCapacidadOpen] = useState(false);
    const toggleCapacidad = () => setpopoverCapacidadOpen(!popoverCapacidadOpen);


    const t = useTranslate();

    return <>
        <span className='iconQuestion'>
            <FontAwesomeIcon color='#454545'  icon={faQuestionCircle} id="PopoverCapacidad" />
        </span>
        <Popover placement="bottom" isOpen={popoverCapacidadOpen} target="PopoverCapacidad" trigger="focus" toggle={toggleCapacidad}>
            <PopoverHeader>Capacidad</PopoverHeader>
            <PopoverBody>
                <p>
                     
                    {t({
                        es: "Manejamos un optimizado sistema de compresión de imágenes, por lo que la capacidad de almacenamiento puede variar dependiendo de las propiedades de las imágenes que subas.",
                        en: "We handle an optimized image compression system, so the storage capacity may vary depending on the properties of the images you upload."
                    })}
                    <br/> 
                    {t({
                        es: "Por ejemplo with 1 GB:",
                        en: "For example with 1 GB:"
                    })}
                    
                    <br/>
                    {t({
                        es: "imagenes de 5MB -- 18000 aprox.",
                        en: "images of 5MB -- 18000 aprox."
                    })}
                    <br/>
                    {t({
                        es: "imagenes de 15MB -- 5000 aprox.",
                        en: "images of 15MB -- 5000 aprox."
                    })}
                    
                    <br/>
                    <strong>
                        {t({es: "Te recomendamos probar nuestro", en: "We recommend you try our" })} <a href="#pruebaMarca">{t({es: "sistema de prueba", en: "test system"})} <FontAwesomeIcon icon={faLink} /></a> {t({es: "para que puedas ver la capacidad real que necesitas.", en: "so you can see the real capacity you need."})}
                    </strong>
                </p>
            </PopoverBody>
        </Popover>
    </>
}