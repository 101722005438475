import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import {useState} from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,

} from 'reactstrap';
import { saveMarcaAgua } from '../../services/services';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTranslate from '../../utils/translate';

export default function ModalChangeMarcaAgua({isOpen, toggle}) {

    const [marcaSeleccionada, setmarcaSeleccionada] = useState(false);
    const [urlMarcaTemporal, seturlMarcaTemporal] = useState('');
    const [estadoImagenSubida, setestadoImagenSubida] = useState('');

    const t = useTranslate();

    function guardarMarcaAgua(){
        if(estadoImagenSubida === 10){

            saveMarcaAgua(urlMarcaTemporal).then(res => {
                console.log(res.data);
                //alert(res.data.message);
                if(res.data.auth){
                    toast(t({
                        es: 'Marca de agua guardada correctamente',
                        en: 'Watermark saved correctly',
                    }), {
                        theme: "dark",
                        autoClose: 3000,
                        theme: "colored",
                        color: "#fffff",
                        backgroundColor: "#08a600",
                    });
                    //aquí recargamos la web
                    window.location.reload();
                }else{
                    toast('⚠️ '+ t({es: res.data.errorMessageUserEs, en: res.data.errorMessageUserEn}), {
                        theme: "dark",
                        autoClose: 3000,
                    });
                }
            });
            
            
        }
        
    }

    function changeInput(e){


        var uploadFile = e.target.files[0];

        if (!window.FileReader) {
            alert(t({
                es: 'Tu navegador no soporta la lectura de archivos',
                en: 'Your browser does not support file reading',
            }));
            return;
        }

        if (!(/\.(jpg|png|jpeg|gif)$/i).test(uploadFile.name)) {
            alert(t({
                es: 'El archivo a adjuntar no es una imagen',
                en: 'The file to attach is not an image',
            }));
        }
        else{
            // Creamos el objeto de la clase FileReader
            let reader = new FileReader();

            // Leemos el archivo subido y se lo pasamos a nuestro fileReader
            reader.readAsDataURL(e.target.files[0]);

            // Le decimos que cuando este listo ejecute el código interno
            reader.onload = (e)=>{

                //esto solo es para el modo desarrollo
                // props.setUrlMarca(e.target.result);
                //-------------------

                var img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    if (this.width.toFixed(0) > 500 || this.height.toFixed(0) > 500) {
                        //alert('La imagen supera las medidas maximas');
                        setestadoImagenSubida(1);
                    }
                    else if(this.width.toFixed(0) < 68 || this.height.toFixed(0) < 1){
                        //alert('la imagen es medasiado pequeña');
                        setestadoImagenSubida(2);
                    }
                    else if (uploadFile.size > 200000)
                    {
                        //alert('El peso de la imagen no puede exceder los 200kb')
                        setestadoImagenSubida(3);
                    }else{
                        setestadoImagenSubida(10);
                    }
                    seturlMarcaTemporal(e.target.result)
                    setmarcaSeleccionada(true);
                };
            }; 
        }


    }

    return <Modal
        centered
        isOpen={isOpen}
        toggle={toggle}
    >
        <ToastContainer/>
        <ModalHeader toggle={toggle}>
            Establecer mi marca de agua
        </ModalHeader>
        
        <ModalBody> 
            <p>
                {t({
                    es: 'Tu marca de agua se dibujará sobre las fotos que quieras, la imagen puede ser de cualquier tipo, pero te recomendamos que sea una imagen .PNG con logotipo blanco y de fondo transparente. La imagen debe medir maximo 500x500 pixeles.',
                    en: 'Your watermark will be drawn on the photos you want, the image can be of any type, but we recommend that it be a .PNG image with a white logo and a transparent background. The image must measure a maximum of 500x500 pixels.',
                })}
                <br/>                
                {t({
                    es: 'Ejemplo:',
                    en: 'Example:',
                })}
            </p>
            <div className="d-flex justify-content-around">
                <img alt="imagen de ejemplo para marca de agua con letras blancas y fondo transparente" src={require('../../images/marcaEjemploPng.jpg')} className="imgMarcaAguaExample"></img>
                <img alt="imagen de ejemplo para marca de agua con letras blancas y fondo negro" src={require('../../images/marcaEjemploNegra.jpg')} className="imgMarcaAguaExample"></img>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mb-2">
                <label className="btn btn-warning">
                    Seleccionar archivo{" "}
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <input type="file" hidden accept="image/*" onChange={changeInput} />
                </label>
            </div>
            <MarcaSeleccionada isSelected={marcaSeleccionada} url={urlMarcaTemporal} estado={estadoImagenSubida}></MarcaSeleccionada>
        </ModalBody>
        <ModalFooter>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={toggle}>Cancelar</button>
                <button type="button" className="btn btn-primary" 
                onClick={guardarMarcaAgua}
                >
                    Guardar Cambios
                </button>
            </div>
        </ModalFooter>
    </Modal>
}



const MarcaSeleccionada = (props) => {
    if(props.isSelected){
        return <div className="d-flex flex-column align-items-center" >
            <div style={{backgroundColor: "#d1d1d1", padding: 10}}>
                <img src={props.url} className="imgRespondive"></img>
            </div>
            <MensajeImagen estado={props.estado}></MensajeImagen>
        </div>
    }else{
        return <div>

        </div>
    }
}

    const MensajeImagen = (props) => {
        const estado = props.estado;
        switch (estado) {
            case 1:
                return <span style={{color: 'red'}}>La imagen supera las medidas permitidas (máximo 500x500)</span>
            case 2:
                return <span style={{color: 'red'}}>La imagen es demasiado pequeña</span>
            case 3:
                return <span style={{color: 'red'}}>La imagen es demasiado pesada</span>
            case 10:
                return <span style={{color: 'green'}}>La imagen es valida</span>
        }
    }