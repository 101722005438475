import './ParamsMarcaAgua.css'

import useTranslate from '../../../utils/translate'

export default function ParamsMarcaAgua({paramsMarcaAgua, setparamsMarcaAgua}) {

    const t = useTranslate();

    return <>
    
        <div className='panelOpacity'>
            <p className='m-0'>
                {t({es: "Opacidad: ", en: "Opacity: "})}
                {parseInt(paramsMarcaAgua.opacidadMarca ? paramsMarcaAgua.opacidadMarca : 15)}%</p>
            <input
                className='sliderOpacity'
                type="range"
                min="0"
                max="100"
                value={paramsMarcaAgua.opacidadMarca ? paramsMarcaAgua.opacidadMarca : 15}
                onChange={(e)=>{
                    setparamsMarcaAgua({...paramsMarcaAgua, opacidadMarca: parseInt(e.target.value)})
                }}
            ></input>

            <p className='m-0'>
                {t({es: "Tamaño: ", en: "Size: "})} 
            {parseInt(paramsMarcaAgua.porcentajeMarcaAgua ? paramsMarcaAgua.porcentajeMarcaAgua : 100)}%</p>
            <input
                className='sliderOpacity'
                type="range"
                min="10"
                max="300"
                value={paramsMarcaAgua.porcentajeMarcaAgua ? paramsMarcaAgua.porcentajeMarcaAgua : 100}
                onChange={(e)=>{
                    setparamsMarcaAgua({...paramsMarcaAgua, porcentajeMarcaAgua: parseInt(e.target.value)})
                }}
            ></input>

            {/* select posición marca agua */}
            <div className="form-group">
                <label htmlFor="posicionMarcaAgua">
                    {t({es: "Posición: ", en: "Position: "})}
                </label>
                <select 
                    className="form-control" id="posicionMarcaAgua" 
                    value={paramsMarcaAgua.posicionMarcaAgua} 
                    onChange={(e)=>{
                        setparamsMarcaAgua({...paramsMarcaAgua, posicionMarcaAgua: e.target.value})
                    }}
                >
                    <option value="scattered">
                        {t({es: "Dispersa", en: "Scattered"})}
                    </option>
                    <option value="center">
                        {t({es: "Centrada", en: "Centered"})}
                    </option>
                    <option value="top-left">
                        {t({es: "Esquina superior izquierda", en: "Top left"})}
                    </option>
                    <option value="top-right">
                        {t({es: "Esquina superior derecha", en: "Top right"})}
                    </option>
                    <option value="bottom-left">
                        {t({es: "Esquina inferior izquierda", en: "Bottom left"})}
                    </option>
                    <option value="bottom-right">
                        {t({es: "Esquina inferior derecha", en: "Bottom right"})}
                    </option>
                </select>
            </div>
        </div>

    </>

}