import {useState, useEffect, useRef} from "react";
import "./Header.css";
import { Navbar } from 'reactstrap';
import Logo from "../../images/selectIcon.png";
import { Link } from "react-router-dom";

import ItemInfoUserHeader from "../ItemInfoUserHeader/ItemInfoUserHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPowerOff } from "@fortawesome/free-solid-svg-icons";

import { useAppContext } from "../../context/AppContext";
import { logOut } from "../../services/services";

import imgIconTranslate from "../../images/translate3.png";

import useTranslate from "../../utils/translate";


export default function Header({loading, logged}) {

  const [context, setContext] = useAppContext();

  const t = useTranslate();
  const toggleTransalte = () => {
    setContext({...context, language: context.language === "es" ? "en" : "es"});
  };

  //cuando cerremos sesión vamos a borrar todas las cookies donde están los datos basicos del usuario y la token
  function cerrarSesion(){
      logOut().then((res) => {
      console.log(res.message);
      });
  } 

  const getStringUserByStringEmail = (email) => {
      if(!email) return ("");
      let string = "";
      for (let i = 0; i < email.length; i++) {
          if (email[i] !== "@") {
              string += email[i];
          } else {
              break;
          }
      }
      return string;
  }



  const [isOpenNavbar, setisOpenNavbar] = useState(false);
  const toggleNavbar = () => setisOpenNavbar(!isOpenNavbar);


  const menuResponsiveRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (menuResponsiveRef.current && !menuResponsiveRef.current.contains(event.target)) {
        // console.log("click fuera del menu");
        isOpenNavbar && toggleNavbar();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuResponsiveRef, isOpenNavbar]);
  
  
  
  
  return (
    <div className="header " >
      
        
        <Navbar dark className="fixed-top" expand="md" container="sm">
          <div className="mr-auto">
            <Link to="/" className="linkLogo">
              <img src={Logo} className="imgLogo"  alt="logo" />
              <span className="textLogo">PickAlbum</span>
            </Link>
          </div>
          

      {
        loading ? 
          <div className="viewLoading lds-dual-ring"></div>
          :
            logged ?
              <>
                <div className="contMenuRight">
                  <div className="itemMenu">
                    <Link to="/panel" className="nav-link">
                      {t({es: "Panel", en: "Panel"})}
                    </Link>
                  </div>
                  <div className="itemMenu">
                    <Link to="/saveSesion" className="nav-link">
                      {t({es: "Crear álbum", en: "Create album"})}
                    </Link> 
                  </div>
                  <div className="itemMenu">
                    <a href="/#pricing" className="nav-link">
                      {t({es: "Precios", en: "Pricing"})}
                    </a> 
                  </div>
                  <ItemInfoUserHeader/>
                  <div className="itemMenu">
                    <button className="btnTranslateNormal" onClick={toggleTransalte}  >
                      <img src={imgIconTranslate} className="imgUser" alt="translate Web" />
                    </button>
                  </div>
                </div>

                {/* este botón es para abrir el menu responsive, esto está un poquito enredado, pero no es entendible la verdad */}
                <div className="contBtnMenuResponsive">
                  <button className="btnMenuResponsive" onClick={toggleNavbar}>
                      <FontAwesomeIcon icon={faBars} />
                  </button>
                </div>

                <div ref={menuResponsiveRef}  className={("contMenuResponsive " + (isOpenNavbar && " showContMenuResponsive"))}>
                  <div className="itemMenuResponsive">
                    <Link to="/panel">
                      {t({es: "Panel", en: "Panel"})}
                    </Link>
                  </div>
                  <div className="itemMenuResponsive">
                    <Link to="/saveSesion">
                      {t({es: "Crear álbum", en: "Create album"})}
                    </Link> 
                  </div>
                  <div className="itemMenuResponsive">
                    <a href="/#pricing">
                      {t({es: "Precios", en: "Pricing"})}
                    </a> 
                  </div>


                  <div className="itemMenuResponsive">
                    <button className="btnTranslate" onClick={toggleTransalte}>
                      <img src={imgIconTranslate} className="imgUser" alt="translate Web" />
                      <span>{t({es: 'Traducir', en: 'Translate'})}</span>
                    </button>
                  </div>            
                  
                  <div className="contTitleUserResponsive">
                    <div>
                      <img src={
                          !context.user.photoURL ? 
                          require("../../images/noImgProfile.jpg"):
                          context.user.photoURL 
                      } 
                      className="imgUser" alt="user" />
                      {
                          context.user.displayName ?
                              <span className="titleText">{context.user.displayName}</span>
                              :
                              <span className="titleText">{getStringUserByStringEmail(context.user.email)}</span>
                      }
                    </div>
                    <button onClick={cerrarSesion}>
                      {t({es: "Cerrar sesión ", en: "Log out "})}
                      <FontAwesomeIcon icon={faPowerOff} />
                    </button>

                  </div>



                      
                </div>
              </> 
            :
            <>
              <div className="contMenuRight">
                <Link className="btn-login" to="/login">
                  {t({es: "Ingresar", en: "Login"})}
                </Link>
                <a className="btn-login" href="#pricing">
                  {t({es: "Precios", en: "Pricing"})}
                </a>
                <div className="itemMenu">
                  <button className="btnTranslateNormal mr-3" onClick={toggleTransalte}  >
                    <img src={imgIconTranslate} className="imgUser" alt="translate Web" />
                  </button>
                </div>
              </div>
              <div className="contBtnMenuResponsive">
                <Link className="btn-login" to="/login">
                  {t({es: "Ingresar", en: "Login"})}
                </Link>
                <a  className="btn-login" href="#pricing">
                  {t({es: "Precios", en: "Pricing"})}
                </a>
                <div className="itemMenu itemMenuRes">
                  <button className="btnTranslateResponsive" onClick={toggleTransalte}  >
                    <img src={imgIconTranslate} className="imgUser" alt="translate Web" />
                  </button>
                </div>
              </div>
            </>
      }
          
     
              
          
          

          


        </Navbar>


    </div>
  );
}



