import { useState, useEffect } from 'react';
import {Link } from 'react-router-dom';

import ModalLoading from '../Components/ModalLoading/ModalLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import Google from "../images/google.png";
import Facebook from "../images/facebook.png";


import { registerUser } from '../services/services';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


 
import { useAppContext } from '../context/AppContext';

import useTranslate from '../utils/translate';
import imgIconTranslate from '../images/translate.png';
import Footer from '../Components/Footer/Footer';

export default function Register(){
  
    const [correo, setcorreo] = useState("");
    const [contraseña, setcontraseña] = useState("");

    const [loading, setloading] = useState(false);
  
    const [showMsjContra, setshowMsjContra] = useState(false);
    const [msjContra, setmsjContra] = useState(0);


    const [context, setContext] = useAppContext();

    const t = useTranslate();
    const toggleTransalte = () => {
      setContext({...context, language: context.language === "es" ? "en" : "es"});
    };




    useEffect(() => {
      // console.log(contraseña.length);
      if(contraseña.length > 0){
        // console.log('contraseña.length < 0');
        setshowMsjContra(true)
        if(contraseña.length < 7){
          setmsjContra(1);
        }else{
          setmsjContra(2);
        }
      }else{
        setshowMsjContra(false)
      }
    }, [contraseña]);
  
   
    //la verificación de logueo se hace desde el onAuthStateChanged de firebase en el App.js y valida las pantallas que se muestran en el react-router-dom
    const handleSubmit = (e) => {
      
        e.preventDefault();

        if(msjContra === 1){
          return;
        }

        setloading(true);


        registerUser({correo, contraseña}).then(res => {
          if(res.auth){
            // sethasLoggedIn(true);
            console.log(res);
          }else{
            console.log(res);
            setloading(false);
            toast('⚠️ ' + t({es: res.errorMessageUserEs, en: res.errorMessageUserEn}), {
              theme: "dark",
              autoClose: 3000,
            });
          }
        });
  
    };
  
    return <div style={{overflow: 'hidden', position: 'relative'}}>
      <ToastContainer/>
      <div className="shape1"></div>
      <div className="shape2"></div>
      <div className='container d-flex justify-content-center align-items-center flex-column contPadreRegister'>
    
        <br/>
        <div className='cont_form_title'>
            <img src={imgIconTranslate} alt="" className="form__imgTranslate" onClick={toggleTransalte} />
            <h1 className="form__title">{t({es: 'Registro!', en: 'Register!'})}</h1>
          </div>
        <br></br>
    
        <div className='contColumnsRegister'>

          <div className='form__content'>
              <div className="form__social">
                <span className="form__social-text">
                  {t({
                    es: 'Registrate con un solo click:',
                    en: 'Register with a single click:',
                  })}
                </span>
      
                <a href="https://api-fotografos.herokuapp.com/api/google/login/federated/google" >
                  <div className='contBtnSocial' style={{backgroundColor: '#dbdbdb'}}>
                    <img src={Google} alt="" className="imgIconSocial" />
                    <span className="textSocialBtn" style={{color: '#6e6e6e'}}>
                      {t({
                        es: 'Ingresa con Google',
                        en: 'Enter with Google',
                      })}
                    </span>                
                  </div>
                </a>
      
                <a href="https://api-fotografos.herokuapp.com/api/facebook/login/federated/facebook" >
                  <div className='contBtnSocial' style={{backgroundColor: '#3b5998'}}>
                    <img src={Facebook} alt="" className="imgIconSocial" />
                    <span className="textSocialBtn" style={{color: 'white'}}>
                      {t({
                        es: 'Ingresa con Facebook',
                        en: 'Enter with Facebook',
                      })}
                    </span>
                  </div>
                </a>
      
              </div>
      
              <br/>
          </div>


          <div className="form__content columnRightRegister">
    
            <p className='form__social-text'>
              {t({
                es: 'Registrate con tu correo:',
                en: 'Register with your email:',
              })}
            </p>
          
            <form action="" className="" onSubmit={handleSubmit}>
              <div className="form__div form__div-one">
                <div className="form__icon">
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
                <div className="form__div-input">
                  <input
                    type="email"
                    className="form__input"
                    onChange={(e) => setcorreo(e.target.value)}
                    value={correo}
                    placeholder={
                      t({
                        es: 'Correo',
                        en: 'Email',
                      })
                    }
                    required
                  />
                </div>
              </div>
      
              <MsjContraseña showMsjContra={showMsjContra} msjContra={msjContra} />
              <div className="form__div form__div-one">
                <div className="form__icon">
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
                <div className="form__div-input">
                  <input
                    type="password"
                    className="form__input"
                    onChange={(e) => setcontraseña(e.target.value)}
                    value={contraseña}
                    placeholder={
                      t({
                        es: 'Contraseña',
                        en: 'Password',
                      })
                    }
                    required
                  />
                </div>
              </div>
      
              


      
              <label>
                <input type="checkbox" required></input>
                {"  "}
                {t({es: 'Acepto la', en: 'I Accept the '})} <Link to='/privacyPolicy' className='linkPoliticaLegal'>{t({es: 'Politica de privacidad', en: 'Privacy Policy'})}</Link>
              </label>
              <button className="form__button" >{t({es: 'Registrar', en: 'Register'})}</button>
              <Link to="/login" className="form__forgot">
                {t({
                  es: 'Ya tienes una cuenta?',
                  en: 'Do you already have an account?',
                })}
              </Link>

            </form>

          </div>
          

          <ModalLoading isOpen={loading}/>


        </div>
    
    
        <br></br>
        <br></br>
      </div>

      <Footer/>

    </div>
  }


  const MsjContraseña = ({showMsjContra, msjContra}) => {

    const t = useTranslate();

    if(!showMsjContra){
      return <>
      </>
    }

    switch (msjContra) {
      case 1:
        return  <span className='text-danger'>{t({
          es: 'La contraseña es demasiado corta!',
          en: 'The password is too short!',
        })}</span>;
    
      default:
        return  <span className='text-success'>
          {t({
            es: 'La contraseña es segura!',
            en: 'The password is secure!',
          })}
        </span>;

    }

  }
  